<template>
  <el-table :data="tableData" style="margin: 10px 0;">
    <el-table-column width="50">
      <template #default="scope">
        <el-popconfirm
          title="Удалить запись безвозвратно?"
          :width="220"
          @confirm="handleRemove(scope.row.item)"
        >
          <template #reference>
            <el-button
              :icon="Delete"
              type="danger"
              size="small"
              circle
            />
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
    <el-table-column prop="partner" label="Партнёр" />
    <el-table-column prop="shop" label="Магазин" />
    <el-table-column prop="orderType" label="Тип заявки" />
  </el-table>
  <el-pagination
    v-model:current-page="page"
    background
    layout="prev, pager, next"
    :page-size="pageSize"
    :page-count="pageCount"
    hide-on-single-page
  />
</template>

<script lang="ts" setup>

import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { iListItem, iOrderType, iPartnerData, iShopItem } from '@/types/models'
import { useStore } from '@/store'
import { Delete } from '@element-plus/icons-vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const emit = defineEmits(['delete'])

const props = defineProps<{
  partnerData: iPartnerData[]
}>()

const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const shops = computed<Array<iShopItem>>(() => store.state.shops)
const partners = computed<Array<iListItem>>(() => store.state.partners)
const pageSize = ref<number>(50)
const page = computed({
  set: (page: number) => {
    router.push({ query: { ...route.query, page } })
  },
  get: () => parseInt(route.query.page?.toString() || '1')
})

const filterFunc = (field: keyof iPartnerData) => {
  return (item: iPartnerData) => {
    let value = route.query[field]
    if (value) {
      if (!Array.isArray(value)) {
        value = [value]
      }
      return value.length === 0 || value.includes(item[field].toString())
    } else {
      return true
    }
  }
}

const items = computed(() => props.partnerData
  .filter(filterFunc('shop_id'))
  .filter(filterFunc('order_type_id'))
  .filter(filterFunc('partner_id'))
)

const pageCount = computed(() => Math.ceil(items.value.length / pageSize.value))

const tableData = computed(() => items.value.slice((page.value - 1) * pageSize.value, (page.value - 1) * pageSize.value + pageSize.value).map(item => {
  return {
    item,
    partner: partners.value.find(p => p.id === item.partner_id)?.title,
    shop: shops.value.find(s => s.id === item.shop_id)?.title,
    orderType: orderTypes.value.find(ot => ot.id === item.order_type_id)?.title
  }
}))

const handleRemove = (item: iPartnerData) => {
  emit('delete', item)
}

</script>
