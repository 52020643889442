<template>
  <div v-loading="request.state.pending">
    <el-button type="primary" @click="handleAdd">Добавить</el-button>
    <tasks-filter ref="filterRef" />
    <tasks-table ref="tableRef" :items="state.items" @edit="handleEdit" @delete="handleDelete" />
    <el-pagination
      background
      hide-on-single-page
      layout="prev, pager, next"
      :default-current-page="state.page"
      :total="state.count"
      :page-size="state.pageSize"
      style="margin-top: 20px;"
      @change="handlePaginate"
    />
    <el-dialog v-model="dialogVisible">
      <tasks-form ref="formRef" :users="filterRef.users" @save="handleSave" />
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import TasksTable from '@/components/tasks/TasksTable.vue'
import { nextTick, reactive, ref, watch } from 'vue'
import TasksForm from '@/components/tasks/TasksForm.vue'
import { iTask } from '@/types/models'
import TasksFilter from '@/components/tasks/TasksFilter.vue'
import useDataRequest from '@/composables/useDataRequest'
import { useRoute, useRouter } from 'vue-router'
import { deleteTask, loadTasks } from '@/api/tasks'

const request = useDataRequest()

const route = useRoute()
const router = useRouter()
const dialogVisible = ref(false)

const formRef = ref()
const tableRef = ref()
const filterRef = ref()

const state = reactive<{
  items: Array<iTask>
  pageSize: number
  page: number
  count: number
}>({
  items: [],
  page: 1,
  pageSize: 100,
  count: 0
})

const load = (params: object) => {
  request.tryRequest(async () => {
    const response = await loadTasks(params)
    state.items = response.data.items
    state.page = response.data.page
    state.pageSize = response.data.pageSize
    state.count = response.data.count
  })
}

const handlePaginate = (page: number) => {
  router.push({
    query: {
      ...route.query,
      page
    }
  })
}

const handleAdd = () => {
  dialogVisible.value = true
  nextTick(() => {
    formRef.value.resetForm()
  })
}

const handleEdit = (data: iTask) => {
  dialogVisible.value = true
  nextTick(() => {
    formRef.value.setData(data)
  })
}

const handleDelete = (id: number) => {
  request.tryRequest(async () => {
    await deleteTask(id)
    reload()
  })
}

const handleSave = () => {
  dialogVisible.value = false
  reload()
}

const reload = () => {
  load(route.query)
}

watch(() => route.query, () => {
  reload()
}, { immediate: true })

</script>
