import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { DocumentTypeEnum, iDocumentType } from '@/types/models'

export const documentTypeUrls = {
  uploadImage: (type: DocumentTypeEnum) => instance.defaults.baseURL + 'document-types/' + type + '/upload-image',
  viewImage: (type: DocumentTypeEnum, params = {}) => instance.getUri({
    url: 'document-types/' + type + '/image',
    params: { ...params, 'access-token': instance.defaults.headers.common['X-Api-Key'] }
  })
}

export default {
  get: (params = {}): AxiosPromise<iDocumentType[]> => instance.get('document-types', params),
  getByType: (type: DocumentTypeEnum, params = {}): AxiosPromise<iDocumentType> => instance.get('document-types/' + type + '/view', params),
  saveForm: (params: FormData) => {
    const type = params.get('type')
    if (type) return instance.putForm('document-types/' + type + '/update', params)
    else return instance.postForm('document-types', params)
  }
}
