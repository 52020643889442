<template>
  <el-form label-position="top" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="6" :xs="12">
        <el-form-item label="ID">
          <el-input v-model="state.id" clearable />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Магазин">
          <multiple-select v-model="state.shop_id" :options="props.shops" />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Тип">
          <multiple-select v-model="state.order_type_id" :options="types" />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Категория">
          <multiple-select v-model="state.category_id" :options="categories" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6" :xs="12">
        <el-form-item label="Название" class="label-relative">
          <template #label>
            Название
            <el-checkbox
              v-model="state.is_title_strict"
              label="Строго"
              class="sub-check"
              false-value="0"
              true-value="1"
            />
          </template>
          <el-input v-model="state.title" clearable />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Артикул">
          <el-input v-model="state.sku" clearable />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Доп">
          <el-select v-model="state.is_additional" clearable placeholder="Все">
            <el-option value="0" label="Основные" />
            <el-option value="1" label="Дополнительные" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Архив">
          <el-select v-model="state.is_archive" clearable placeholder="Все">
            <el-option value="0" label="Активные" />
            <el-option value="1" label="Архивные" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="&nbsp;">
      <el-button native-type="submit" type="success">Применить</el-button>
      <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>

import { computed } from 'vue'
import { iCategory, iOrderType, iShop } from '@/types/models'
import useFilter from '@/composables/useFilter'
import { iServiceFilter } from '@/types/forms'
import MultipleSelect from '@/components/MultipleSelect.vue'

const {
  state,
  doReset,
  doFilter,
  isFiltered
} = useFilter<iServiceFilter>({
  id: '',
  title: '',
  sku: '',
  is_archive: '',
  is_additional: '',
  is_title_strict: '0',
  shop_id: [],
  order_type_id: [],
  category_id: []
})

const props = defineProps<{
  orderTypes: iOrderType[]
  shops: iShop[]
}>()

const types = computed(() => [
  {
    title: 'Все типы',
    id: 'NULL'
  },
  ...props.orderTypes
])

const categories = computed<Array<Record<string, null|string>|iCategory>>(() => {
  const nullValue = {
    order_type_id: null,
    title: 'Все категории',
    id: 'NULL'
  }

  const oTypes = state.order_type_id?.length
    ? props.orderTypes.filter(value => state.order_type_id?.includes(value.id.toString()))
    : props.orderTypes

  const result: Array<iCategory> = oTypes.reduce((carry: iCategory[], item: iOrderType) => {
    item.categories.forEach(category => {
      if (carry.find(i => i.id === category.id) === undefined) {
        carry.push(category)
      }
    })
    return carry
  }, [] as Array<iCategory>)

  return [
    nullValue,
    ...result
  ]
})

</script>

<style lang="scss">
.label-relative > label{
  position: relative;

  .sub-check {
    position: absolute;
    right: 0;
    bottom: -5px;
  }
}
</style>
