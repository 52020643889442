<template>
  <document-form :record-id="recordId" @close="handleClose" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import useBackNavigation from '@/composables/useBackNavigation'
import DocumentForm from '@/components/documents/DocumentForm.vue'

const route = useRoute()
const navigateBack = useBackNavigation('documents')

const handleClose = () => {
  navigateBack({ id: route.params.type_id })
}

const recordId = computed(() => (route.params.id === 'new' ? null : parseInt(String(route.params.id))) || undefined)
</script>
