<template>
  <div>
    <el-button @click="handleCreate">
      Новая локация
    </el-button>
    <location-index ref="indexRef" @edit-record="handleEdit" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LocationIndex from '@/components/locations/LocationIndex.vue'

const router = useRouter()
const route = useRoute()
const indexRef = ref<InstanceType<typeof LocationIndex>>(null)

const handleCreate = () => {
  handleEdit('new')
}
const handleEdit = (id: number|'new') => {
  router.push({
    name: 'location-update',
    params: {
      id
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}
</script>
