<template>
  <master-form :record-id="recordId" @close="handleClose" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import MasterForm from '@/components/masters/MasterForm.vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const handleClose = () => {
  router.push(route.query.returnUrl !== null
    ? {
        name: 'masters',
        query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
      }
    : {
        name: 'masters'
      })
}

const recordId = computed(() => (route.params.id === 'new' ? null : parseInt(String(route.params.id))) || undefined)
</script>
