<template>
  <div v-if="props.order">
    <h2>Заявка {{ props.order.number }}</h2>
    <p>Клиент (ФИО): {{ props.order.customer }}</p>
    <p>Адрес (монтажа): {{ props.order.address_street }}</p>
    <p>Телефон: {{ props.order.phone }}</p>
    <p>Телефон: {{ props.order.phone2 }}</p>
    <el-table :data="services">
      <el-table-column prop="title" />
      <el-table-column prop="unit"  width="50" />
      <el-table-column prop="formattedPrice" width="150" />
      <el-table-column prop="qty" width="100" />
      <el-table-column prop="total" width="150" />
    </el-table>
    <p>Итого стоимость замера/установки: {{ formatCurrency(total) }}</p>
  </div>
</template>

<script lang="ts" setup>
import { iOrderView } from '@/types/models'
import { computed } from 'vue'
import { formatCurrency } from '@/utils/format'

const props = defineProps<{
  order: iOrderView
}>()

const services = computed(() => props.order.orderServices.map(value => {
  return {
    ...value,
    total: formatCurrency(value.price * value.qty),
    formattedPrice: formatCurrency(value.price)
  }
}))

const total = computed(() => props.order.orderServices.reduce((carry, item) => {
  return carry + (item.price * item.qty)
}, 0))

</script>
