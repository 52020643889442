import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { iShop } from '@/types/models'
import { iRestCollection } from '@/types/responses'
import { iShopForm, Nullable } from '@/types/forms'

export default {
  get: (params = {}): AxiosPromise<iRestCollection<iShop>> => instance.get('shops', params),
  getById: (id: number, params = {}): AxiosPromise<iShop> => instance.get('shops/' + id, params),
  list: (params = {}): AxiosPromise<iShop> => instance.get('shops/list', params),
  allUserShops: () => instance.get<iShop[]>('shops/user-shops'),
  save: (params: Nullable<iShopForm>) => {
    if (params.id) return instance.put('shops/' + params.id, params)
    else return instance.post('shops', params)
  },
  remove: (id: number) => instance.delete('shops/' + id)
}
