import { DocumentStatusEnum } from '@/types/models'

type iDocumentStatusMapItem = {
  text: string
  type: string
  tooltip: string
}

const documentStatusMap: Record<DocumentStatusEnum, iDocumentStatusMapItem> = {
  draft: {
    text: 'Черновик',
    type: 'info',
    tooltip: 'Не будет показываться никому, пока не будет опубликован'
  },
  published: {
    text: 'Опубликован',
    type: 'success',
    tooltip: 'Будет виден всем подходящим под условия мастерам'
  },
  archived: {
    text: 'В архиве',
    type: 'error',
    tooltip: 'Будет виден всем подходящим под условия мастерам, но с пометной, что документ в архиве'
  }
}

export default documentStatusMap
