import { iService, iOrderService } from '@/types/models'

export interface ServicesTableDataInterface {
  id?: number
  isHeader?: boolean
  title: string
  unit: string
  price: string|number
  qty: string|number
  total: string|number
}

export type TableServiceType = iService & Partial<iOrderService>

const convertTableData = (service: TableServiceType): ServicesTableDataInterface => ({
  id: service.id,
  title: service.title,
  unit: service.unit,
  price: service.price,
  qty: service?.qty || 0,
  total: Math.round((service?.qty || 0) * service.price * 100) / 100
})

export const makeServiceTableData = (source: Array<TableServiceType>) => {
  let items: Array<ServicesTableDataInterface> = [{
    isHeader: true,
    title: 'Название услуги',
    unit: 'Ед. Изм.',
    price: 'Цена',
    qty: 'Кол-во',
    total: 'Сумма'
  }]

  const services: { main: Array<TableServiceType>, extra: Array<TableServiceType> } = { main: [], extra: [] }

  source.forEach(i => {
    services[i.is_additional ? 'extra' : 'main'].push(i)
  })

  services.main.sort((a, b) => a.title.localeCompare(b.title))
  services.extra.sort((a, b) => a.title.localeCompare(b.title))

  items = [...items, ...services.main.map(convertTableData)]

  if (services.extra.length) {
    items = [...items, {
      isHeader: true,
      title: 'Дополнительные услуги',
      unit: 'Ед. Изм.',
      price: 'Цена',
      qty: 'Кол-во',
      total: 'Сумма'
    }, ...services.extra.map(convertTableData)]
  }
  return items
}

export const tableRowClassName = ({ row }: { row: ServicesTableDataInterface }) => {
  if (row.isHeader) {
    return 'service-header'
  }
  return ''
}
