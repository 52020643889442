<template>
  <document-type-form :record-type="recordType" @close="handleClose" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import DocumentTypeForm from '@/components/documents/DocumentTypeForm.vue'
import useBackNavigation from '@/composables/useBackNavigation'
import { DocumentTypeEnum } from '@/types/models'

const route = useRoute()
const navigateBack = useBackNavigation('document-types')

const handleClose = () => {
  navigateBack()
}

const recordType = computed(() => String(route.params.type) as DocumentTypeEnum)
</script>
