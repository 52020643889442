// Полные типы услуг

import useDataRequest from '@/composables/useDataRequest'
import { onMounted, ref } from 'vue'
import restServiceCategories from '@/api/rest/restServiceCategories'
import restOrderTypes from '@/api/rest/restOrderTypes'
import { iOrderType, iServiceCategory } from '@/types/models'

const useOrderTypes = () => {
  const request = useDataRequest()
  const orderTypes = ref<iOrderType[]>([])
  onMounted(() => {
    request.tryRequest(async () => {
      const typesResponse = await restOrderTypes.get()
      const categoriesResponse = await restServiceCategories.list()

      orderTypes.value = typesResponse.data.map<iOrderType>((value: iOrderType) => {
        return {
          id: value.id,
          title: value.title,
          categories: categoriesResponse.data.items
            .filter((v: iServiceCategory) => v.order_type_id === value.id)
            .map((v: iServiceCategory) => {
              return {
                id: v.id,
                title: v.title
              }
            })
        } as iOrderType
      })
    })
  })

  return {
    request,
    orderTypes
  }
}

export default useOrderTypes
