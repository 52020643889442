<template>
  <el-form-item prop="is_calculated_prices" label="&nbsp;">
    <el-checkbox
      label="Автоматический расчет"
      :disabled="props.disabled || props.withoutServices"
      :model-value="props.is_calculated_prices"
      @update:model-value="e => $emit('update:is_calculated_prices', e)"
    />
  </el-form-item>

  <el-row :gutter="20">
    <el-col :span="6" :xs="24">
      <el-form-item prop="cost" label="Сумма" :error="props.errors.cost">
        <el-input
          :disabled="isDisabled"
          :model-value="props.cost"
          @update:model-value="e => $emit('update:cost', e)"
        />
      </el-form-item>
    </el-col>

    <el-col :span="6" :xs="24">
      <el-form-item prop="payment_part_extra" label="Сумма доп работ (нал)" :error="props.errors.payment_part_extra">
        <el-input
          :disabled="isDisabled"
          :model-value="props.payment_part_extra"
          @update:model-value="e => $emit('update:payment_part_extra', e)"
        />
      </el-form-item>
    </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="6" :xs="24">
      <el-form-item prop="is_payment_immediately">
        <el-checkbox
          label="Оплата на месте"
          :model-value="props.is_payment_immediately"
          :disabled="props.disabled"
          @update:model-value="e => $emit('update:is_payment_immediately', e)"
        />
      </el-form-item>
    </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="6" :xs="24">
      <el-form-item prop="payment_part_shop" label="Сумма магазину" :error="props.errors.payment_part_shop">
        <el-input
          :model-value="props.payment_part_shop"
          :disabled="isDisabled"
          @update:model-value="e => $emit('update:payment_part_shop', e)"
        />
      </el-form-item>
    </el-col>

    <el-col :span="6" :xs="24">
      <el-form-item prop="payment_date_shop" label="Дата оплаты магазину" :error="props.errors.payment_date_shop">
        <el-date-picker
          :model-value="props.payment_date_shop"
          value-format="YYYY-MM-DD"
          format="DD.MM.YYYY"
          :disabled="!canUpdatePaymentDateShop"
          @update:model-value="e => $emit('update:payment_date_shop', e)"
        />
      </el-form-item>
    </el-col>

    <el-col :span="6" :xs="24">
      <el-form-item label="Баланс мастера">
        <el-input
          disabled
          :model-value="props.master_balance"
          @update:model-value="e => $emit('update:master_balance', e)"
        />
      </el-form-item>
    </el-col>

    <el-col :span="6" :xs="24">
      <el-form-item label="Долг компании">
        <el-input
          disabled
          :model-value="props.company_debt"
          @update:model-value="e => $emit('update:company_debt', e)"
        />
      </el-form-item>
    </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="6" :xs="24">
      <el-form-item prop="payment_part_master" label="Сумма мастеру осн" :error="props.errors.payment_part_master">
        <el-input
          :model-value="props.payment_part_master"
          :disabled="isDisabled"
          @update:model-value="e => $emit('update:payment_part_master', e)"
        />
      </el-form-item>
    </el-col>

    <el-col :span="6" :xs="24">
      <el-form-item prop="payment_part_master_extra" label="Сумма мастеру доп" :error="props.errors.payment_part_master_extra">
        <el-input
          :model-value="props.payment_part_master_extra"
          :disabled="isDisabled"
          @update:model-value="e => $emit('update:payment_part_master_extra', e)"
        />
      </el-form-item>
    </el-col>

    <el-col :span="6" :xs="24">
      <el-form-item prop="payment_date_master" label="Дата опл мастеру" :error="props.errors.payment_date_master">
        <el-date-picker
          :model-value="props.payment_date_master"
          value-format="YYYY-MM-DD"
          format="DD.MM.YYYY"
          :disabled="!canUpdatePaymentDateMaster"
          @update:model-value="e => $emit('update:payment_date_master', e)"
        />
      </el-form-item>
    </el-col>

    <el-col :span="6" :xs="24">
      <el-form-item label="Долг мастера">
        <el-input
          disabled
          :model-value="props.master_debt"
          @update:model-value="e => $emit('update:master_debt', e)"
        />
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>

import { iFormErrors } from '@/types/responses'
import { iOrderManagerForm, iOrderPriceFields } from '@/types/forms'
import { computed, watch } from 'vue'
import { iOrderService, iService } from '@/types/models'
import useOrderCalculator from '@/composables/useOrderCalculator'
import useCanUpdatePaymentDate from '@/composables/useCanUpdatePaymentDate'

interface iProps extends iOrderPriceFields {
  errors: iFormErrors<iOrderManagerForm>
  disabled?: boolean
  withoutServices?: boolean
  services: iService[]
  orderServices: iOrderService[]
  statusId: number
}

const props = defineProps<iProps>()

const isDisabled = computed(() => props.disabled || props.is_calculated_prices)
const calculate = useOrderCalculator()

const { canUpdatePaymentDateMaster, canUpdatePaymentDateShop } = useCanUpdatePaymentDate()

const emit = defineEmits([
  'update:cost',
  'update:payment_part_master',
  'update:payment_part_master_extra',
  'update:payment_part_shop',
  'update:payment_part_shop_extra',
  'update:master_debt',
  'update:company_debt',
  'update:master_balance'
])

const recalculatePercents = () => {
  if (props.statusId !== 1 && props.statusId !== 3) {
    const result = calculate(props.orderServices, props.services)

    emit('update:payment_part_shop', result.payment_part_shop)
    emit('update:payment_part_shop_extra', result.payment_part_shop_extra)
    emit('update:payment_part_master', result.payment_part_master)
    emit('update:payment_part_master_extra', result.payment_part_master_extra)
    emit('update:cost', result.cost)
  }
}

watch(() => [props.orderServices, props.is_calculated_prices], () => {
  if (props.is_calculated_prices) {
    recalculatePercents()
  }
})

watch(() => [
  props.cost,
  props.payment_part_master,
  props.is_payment_immediately,
  props.payment_part_shop,
  props.payment_part_extra,
  props.payment_part_master_extra
], () => {
  const cost = props.cost ? parseFloat(props.cost.toString()) || 0 : 0
  const partExtra = props.payment_part_extra ? parseFloat(props.payment_part_extra.toString()) || 0 : 0
  const partMaster = props.payment_part_master ? parseFloat(props.payment_part_master.toString()) || 0 : 0
  const partMasterExtra = props.payment_part_master_extra ? parseFloat(props.payment_part_master_extra.toString()) || 0 : 0

  const masterDebt = (props.is_payment_immediately ? cost : 0) + partExtra
  const companyDebt = partMaster + partMasterExtra

  const masterBalance = companyDebt - masterDebt

  emit('update:master_debt', masterDebt)
  emit('update:company_debt', companyDebt)
  emit('update:master_balance', masterBalance)
})

</script>
