<template>
  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
  >
    <div class="form-title">
      <h2 v-if="!isUpdateForm">Новый документ</h2>
      <h2 v-else>Редактирование документа {{ recordTitle }}</h2>

      <div>
        <el-button v-if="state.canGenerateServicePrice" type="warning" @click="generateServicePrice">Сгенерировать прайс</el-button>
        <el-button @click="$emit('close')">Назад</el-button>
      </div>
    </div>

    <el-row :gutter="20">
      <el-col :xs="24" :span="12">
        <el-form-item prop="title" label="Наименование" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="4">
        <el-form-item prop="status" label="Статус" :error="state.errors.status">
          <el-select v-model="state.form.status" default-first-option>
            <el-option v-for="(item, value) in documentStatusMap" :key="value" :value="value" :label="item.text" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item v-if="state.form.type === 'prices'" prop="shop_id" label="Магазин" :error="state.errors.shop_id">
      <el-select
        v-model="state.form.shop_id"
        filterable
        clearable
      >
        <el-option
          v-for="option in shops"
          :key="option.id"
          :value="option.id"
          :label="option.title"
        />
      </el-select>
    </el-form-item>

    <el-form-item prop="location_ids" label="Локации" :error="state.errors.location_ids">
      <el-select
        v-model="state.form.location_ids"
        filterable
        clearable
        multiple
      >
        <el-option
          v-for="option in locations"
          :key="option.id"
          :value="option.id"
          :label="option.title"
        />
      </el-select>
    </el-form-item>

    <el-form-item prop="category_ids" label="Категории услуг" :error="state.errors.category_ids">
      <el-select
        v-model="state.form.category_ids"
        filterable
        clearable
        multiple
      >
        <el-option
          v-for="option in categories"
          :key="option.id"
          :value="option.id"
          :label="option.title"
        />
      </el-select>
    </el-form-item>

    <el-row :gutter="20">
      <el-col :xs="24" :span="24">
        <el-form-item prop="content" label="Текст документа" :error="state.errors.content">
          <element-tiptap :key="editorKey"
                          v-model:content="state.form.content"
                          :extensions="[Doc, Text, Paragraph, History, Bold, Italic, Underline, Strike, BulletList, OrderedList, Table, TextAlign, Heading, FormatClear]"
                          height="600px"
                          :readonly="state.form.type === 'prices'"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="uploadFiles" label="Вложения" :error="state.errors.uploadFiles">
          <el-upload name="attachments"
                     v-model:file-list="state.form.uploadFiles"
                     multiple
                     show-file-list
                     :auto-upload="false"
                     :before-remove="beforeRemoveAttachment"
                     :on-preview="downloadAttachment"
          >
            <el-button type="primary">Добавить файлы</el-button>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>

    <el-divider />

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { ElMessage, ElMessageBox, FormInstance, FormRules, UploadFile, UploadUserFile } from 'element-plus'
import { iBaseFormState, iDocumentForm } from '@/types/forms'
import { DocumentTypeEnum, iCategory, iLocation, iShop } from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restDocuments, { documentUrls } from '@/api/rest/restDocuments'
import documentStatusMap from '@/data/documentStatusMap'
import { ElementTiptap, Doc, Text, Paragraph, Heading, Bold, Underline, Italic, Strike, BulletList, OrderedList, Table, TextAlign, FormatClear, History } from 'element-tiptap-vue3-fixed'
import useComponentReload from '@/composables/useComponentReload'
import restShops from '@/api/rest/restShops'
import restLocations from '@/api/rest/restLocations'
import restServiceCategories from '@/api/rest/restServiceCategories'
import { downloadFileByUrlToBrowser } from '@/api/files'
import { useRoute } from 'vue-router'

type StateInterface = iBaseFormState<iDocumentForm & { uploadFiles: UploadUserFile[] }> & {
  loading: boolean,
  errors: Record<string, string>
  canGenerateServicePrice: boolean
}

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const formRef = ref<FormInstance>()
const isUpdateForm = !!props.recordId
const recordTitle = ref('')
const shops = ref<iShop[]>([])
const locations = ref<iLocation[]>([])
const categories = ref<iCategory[]>([])
const [editorKey, editorReload] = useComponentReload()

const route = useRoute()

const state = reactive<StateInterface>({
  loading: false,
  form: {
    id: null,
    type: 'standards',
    title: '',
    status: 'draft',
    content: '',
    attachments: [],
    location_ids: [],
    category_ids: [],
    shop_id: null,
    uploadFiles: []
  },
  canGenerateServicePrice: false,
  errors: {}
})

const rules = computed<FormRules<iDocumentForm>>(() => {
  const rules = {} as FormRules<iDocumentForm>

  const fields: Array<keyof iDocumentForm> = ['type', 'title', 'status']
  if (state.form.type === 'prices') {
    fields.push('shop_id')
  }

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  return rules
})

const beforeRemoveAttachment = (uploadFile: UploadFile & { id: number }) => {
  if (!uploadFile.id) {
    return true
  }

  return ElMessageBox.confirm(
    `Подтвердите удаления файла ${uploadFile.name} ?`
  ).then(
    async () => {
      if (!state.form.id) {
        ElMessage.error('Непредвиденная ошибка')
        return false
      }

      state.loading = true

      try {
        await restDocuments.attachments.delete(+state.form.id, uploadFile.id)
        return true
      } catch (e: unknown) {
        ElMessage.error('Непредвиденная ошибка')
        console.error(e)
        return false
      } finally {
        state.loading = false
      }
    },
    () => false
  )
}

const downloadAttachment = (uploadFile: UploadFile & { id?: number }) => {
  if (!state.form.id) return

  downloadFileByUrlToBrowser(documentUrls.viewAttachment(state.form.id, uploadFile.id as number), uploadFile.name)
}

const loadForm = (recordId: number) => {
  state.loading = true

  restDocuments.getById(recordId, {
    params: {
      fields: ['*', 'locations.id', 'categories.id', 'shop.id'].join(','),
      expand: ['categories', 'locations', 'attachments', 'shop'].join(',')
    }
  })
    .then(response => {
      const responseModel = response.data

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iDocumentForm

        if (key in responseModel) {
          state.form[key] = responseModel[key]
        }
      }

      state.form.category_ids = responseModel.categories.map(item => item.id)
      state.form.location_ids = responseModel.locations.map(item => item.id)
      state.form.shop_id = responseModel.shop?.id || null
      state.form.uploadFiles = responseModel.attachments.map(file => ({ name: file.title, id: file.id }))
      state.canGenerateServicePrice = responseModel.type === 'prices'

      recordTitle.value = responseModel.title
      editorReload()
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const form = new FormData()
  for (const key in { ...state.form }) {
    form.append(key, state.form[key])
  }
  if (!state.form.id) {
    form.delete('id')
  }
  form.delete('attachments')
  form.delete('categories')
  form.delete('shop')
  form.delete('locations')

  if (state.form.uploadFiles) {
    for (const file of state.form.uploadFiles) {
      if (file.status === 'ready') {
        form.append('uploadFiles[]', file.raw as File)
      }
    }
  }

  try {
    const response = await restDocuments.saveForm(form)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}

const generateServicePrice = async () => {
  if (!state.form.id) { return }

  try {
    await restDocuments.generateServicesPrice(state.form.id)
    loadForm(state.form.id)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      ElMessage.error({
        message: 'Ошибка: ' + e.errors.message
      })
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
  }
}

const loadShops = () => restShops.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    shops.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })

const loadLocations = () => restLocations.list({ params: { fields: ['id', 'title', 'lat', 'lng'].join(',') } })
  .then(response => {
    locations.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })

const loadCategories = () => restServiceCategories.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    categories.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
onMounted(async () => {
  state.loading = true

  await loadShops()
  await loadLocations()
  await loadCategories()

  state.loading = false

  if (!props.recordId) return

  loadForm(props.recordId)
})

watch(() => route.params.type, () => {
  state.form.type = route.params.type as DocumentTypeEnum
}, { immediate: true })

</script>
