<template>
  <el-autocomplete
    v-loading="request.state.pending"
    v-model="state"
    :fetch-suggestions="querySearchAsync"
    :placeholder="props.placeholder"
    @select="handleSelect"
    @clear="handleClear"
    @focus="handleFocus"
    @blur="handleBlur"
    @click="handleFocus"
    :clearable="props.clearable"
    :disabled="props.disabled"
    style="width: 100%;"
  >
    <template #default="{ item }">
      <div :style="item.is_active ? {} : { opacity: 0.4 }">{{ item.value }}</div>
    </template>
  </el-autocomplete>
</template>

<script lang="ts" setup>

import { ref, watch } from 'vue'
import { iMaster } from '@/types/models'
import useDataRequest from '@/composables/useDataRequest'
import restMasters from '@/api/rest/restMasters'
import { useStore } from '@/store'

type iMasterOption = {
  value: string
  // eslint-disable-next-line camelcase
  is_active: boolean
  id: number|'NULL'
}

const props = withDefaults(defineProps<{
  placeholder?: string
  modelValue?: number|null|'NULL'
  clearable?: boolean
  disabled?: boolean
  canNull?: boolean
  locationId?: number
  categoryId?: number
}>(), {
  placeholder: '',
  clearable: false,
  disabled: false
})

const emit = defineEmits(['update:modelValue'])

const store = useStore()

const state = ref('')

// Штука чтобы не грузить данные только если были изменения из вне
const oldValue = ref<number|null|'NULL'>(null)

const oldTerm = ref('')

const handleSelect = (item: iMasterOption) => {
  oldValue.value = item.id
  oldTerm.value = item.value
  emit('update:modelValue', item.id)
}

const handleClear = () => {
  emit('update:modelValue', null)
}

const handleBlur = () => {
  state.value = oldTerm.value
}

const handleFocus = () => {
  state.value = ''
}

const request = useDataRequest()

const querySearchAsync = (searchQuery: string, cb: (arg: unknown) => void) => {
  const filter: Partial<Record<keyof iMaster, unknown>> = { title: { like: state.value } }

  if (!store.state.user.isAdmin) {
    filter.partner_id = store.state.user.partner_id
  }

  if (props.locationId) {
    filter.location_id = props.locationId
  }

  const params: {
    categories?: unknown
    filter: unknown
    sort: string
  } = { filter, sort: 'title' }

  if (props.categoryId) {
    params.categories = props.categoryId
  }

  request.tryRequest(async () => {
    const items = (await restMasters.get({ params })).data.items.map<iMasterOption>(item => ({
      value: item.title,
      is_active: item.is_active,
      id: item.id as number
    }))

    if (props.canNull) {
      items.unshift({
        value: 'Без мастера',
        id: 'NULL',
        is_active: true
      })
    }

    cb(items)
  })
}

watch(() => props.modelValue, () => {
  if (props.modelValue) {
    if (oldValue.value !== props.modelValue) {
      if (props.modelValue === 'NULL') {
        oldTerm.value = state.value = 'Без мастера'
      } else {
        request.tryRequest(async () => {
          oldTerm.value = state.value = (await restMasters.getById(props.modelValue as number)).data.title
        })
      }
      oldValue.value = props.modelValue
    }
  } else {
    oldTerm.value = state.value = ''
  }
}, { immediate: true })

</script>
