<template>
  <div v-loading="state.loading">
    <el-row justify="end">
      <el-col :span="6">
        <el-input v-model="state.filter.search" placeholder="Поиск" @change="doFilter" clearable/>
      </el-col>
    </el-row>

    <el-table
      ref="tableRef"
      :data="tableData"
      empty-text="Ничего не нашлось"
      table-layout="auto"
      :default-sort="{ prop: 'id', order: 'descending' }"
      @sort-change="sortData"
    >
      <el-table-column label="Действия" header-align="center" align="center">
        <template #default="scope">
          <el-button type="success" :icon="EditPen" circle @click="$emit('editRecord', scope.row.id)"/>
        </template>
      </el-table-column>

      <el-table-column prop="id" label="ID" sortable :sort-orders="['descending', 'ascending']" />
      <el-table-column prop="title" label="ФИО" sortable />
      <el-table-column prop="phone" label="Телефон" />
      <el-table-column prop="is_archive" label="Архив" :formatter="booleanFormatter" />
      <el-table-column prop="is_thursday" label="Четверг" :formatter="booleanFormatter" />
      <el-table-column prop="is_wednesday" label="Среда" :formatter="booleanFormatter" />
      <el-table-column prop="is_tuesday" label="Вторник" :formatter="booleanFormatter" />
    </el-table>
    <el-row justify="end">
      <el-pagination background layout="prev, pager, next" :total="state._meta.totalCount"
                     :page-size="state._meta.perPage" v-model:current-page="state._meta.currentPage"
                     :page-count="state._meta.pageCount" @change="doFilter"/>
    </el-row>
  </div>
</template>

<script setup lang="ts">

import { computed, reactive, ref, watch } from 'vue'
import { iMaster } from '@/types/models'
import { useRoute } from 'vue-router'
import { EditPen } from '@element-plus/icons-vue'
import { ElMessage, TableColumnCtx, TableInstance } from 'element-plus'
import router from '@/router'
import restMasters from '@/api/rest/restMasters'

const route = useRoute()
defineEmits(['editRecord'])

interface iFilter {
  search: string
}

const booleanFormatter = (row: iMaster, column: TableColumnCtx<iMaster>, cellValue: boolean) => cellValue ? 'Да' : 'Нет'

const tableRef = ref<TableInstance>()
const state = reactive<{
  items: Array<iMaster>
  filter: iFilter
  loading: boolean
  _meta: {
    totalCount: number
    pageCount: number
    currentPage: number
    perPage: number
  }
}>({
  loading: true,
  filter: {
    search: route.query?.search as string || ''
  },
  items: [],
  _meta: {
    totalCount: 0,
    pageCount: 0,
    currentPage: Number(route.query.page) || 1,
    perPage: Number(route.query.perPage) || 20
  }
})

const tableData = computed(() => state.items.map(item => ({
  ...item,
  is_archive: !item.is_active
})))

const load = () => {
  const sort = route.query.sort ?? '-id'
  const queryParams = {
    params: {
      fields: ['id', 'title', 'password', 'phone', 'is_active', 'is_thursday', 'is_wednesday', 'is_tuesday'].join(','),
      ...route.query,
      sort
    }
  }

  state.loading = true

  restMasters.get(queryParams)
    .then(response => {
      state.items = response.data.items
      state._meta = response.data._meta
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const doFilter = () => {
  const query = {
    ...state.filter,
    page: state._meta.currentPage,
    perPage: state._meta.perPage
  }

  router.push({ query })
}
const sortData = (sort: { order: string | null, prop: string }) => {
  const query = {
    ...route.params
  }

  if (sort.order === null) {
    delete query.sort
    tableRef.value?.sort('id', 'descending')
  } else if (sort.order === 'ascending') {
    query.sort = sort.prop
  } else if (sort.order === 'descending') {
    query.sort = '-' + sort.prop
  }

  router.push({ query })
}

defineExpose({
  load
})

watch(() => route.query, () => {
  load()
}, { immediate: true })

</script>
