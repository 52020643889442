<template>
  <el-button v-if="props.showClose" class="form-back-btn" @click="emit('close')">Назад</el-button>
  <el-row :gutter="20" v-loading="pending">
    <el-col :span="12" :xs="24" class="right-divider">

      <h2>{{ title }}</h2>

      <el-form
        ref="formRef"
        label-position="top"
        :model="state.form"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="6" :xs="24">
            <el-form-item label="Дата заказа" prop="date_order" :error="requests.form.state.errors.date_order">
              <el-date-picker
                v-model="state.form.date_order"
                value-format="YYYY-MM-DD"
                format="DD.MM.YYYY"
                disabled
                :shortcuts="pickerShortcuts.orderForm"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4" :xs="12">
            <el-form-item label="Дата ориент." prop="date_expected" :error="requests.form.state.errors.date_expected">
              <el-date-picker
                v-model="state.form.date_expected"
                value-format="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :disabled="isDisabled"
                :shortcuts="pickerShortcuts.orderForm"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2" :xs="8">
            <el-form-item label="Б/Д" prop="ignore_expected_date">
              <el-checkbox v-model="state.form.ignore_expected_date" :disabled="isDisabled" />
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="24">
            <el-form-item prop="date_assemble" label="Дата сборки" :error="requests.form.state.errors.date_assemble">
              <el-date-picker
                v-model="state.form.date_assemble"
                value-format="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :disabled="isDisabled"
                :shortcuts="pickerShortcuts.orderForm"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="24">
            <el-form-item prop="date_call" label="Дата созвона" :error="requests.form.state.errors.date_call">
              <el-date-picker
                v-model="state.form.date_call"
                value-format="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :disabled="isDisabled"
                :shortcuts="pickerShortcuts.orderForm"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :xs="12">
            <el-form-item label="Время ориент." prop="time_expected" :error="requests.form.state.errors.time_expected" class="time-select-wrapper">
              <expected-time-select v-model="state.form.time_expected" :disabled="isDisabled" />
              <el-row :gutter="20">
                <el-col :span="12" :xs="24">
                  <el-button type="text" @click="setExpectedTime('09:00-14:00')" style="cursor:pointer;">09:00 - 14:00</el-button>
                </el-col>
                <el-col :span="12" :xs="24">
                  <el-button type="text" @click="setExpectedTime('13:00-18:00')" style="cursor:pointer;">13:00 - 18:00</el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="12">
            <el-form-item label="Время ориент. мастера" prop="delivery_time">
              <el-input :model-value="state.form.delivery_time" disabled />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-row :gutter="20">
              <el-col :span="24" :xs="12">
                <el-form-item prop="is_urgent">
                  <el-checkbox
                    v-model="state.form.is_urgent"
                    :disabled="isDisabled"
                    label="Срочность"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24" :xs="12">
                <el-form-item prop="is_lying">
                  <el-checkbox
                    v-model="state.form.is_lying"
                    :disabled="isDisabled"
                    label="Ложный выезд"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-loading="requests.services.state.pending">
          <el-col :span="6" :xs="24">
            <el-form-item prop="shop_id" label="Магазин" :error="requests.form.state.errors.shop_id">
              <el-select
                v-model="shopId"
                filterable
                :disabled="shops.length === 1 || !isNew"
                @change="handleShopChange"
              >
                <el-option
                  v-for="option in shops"
                  :key="option.id"
                  :value="option.id"
                  :label="option.title"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="type_id" label="Тип заявки" :error="requests.form.state.errors.type_id">
              <el-select
                v-model="state.form.type_id"
                :disabled="types.length === 1 || !isNew"
                no-data-text="Выберите магазин"
                @change="handleTypeChange"
              >
                <el-option v-for="type in types" :key="type.id" :value="type.id" :label="type.title" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="category_id" label="Категория" :error="requests.form.state.errors.category_id">
              <el-select
                v-model="state.form.category_id"
                clearable
                no-data-text="Выберите магазин и тип заявки"
                :disabled="!isNew"
                @change="handleCategoryChange"
              >
                <el-option v-for="category in categories" :key="category.value" :value="category.value" :label="category.label" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="number" label="Номер" :error="requests.form.state.errors.number">
              <el-input v-model="state.form.number" :disabled="isDisabled" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6" :xs="24">
            <el-form-item prop="customer" label="Клиент (ФИО)" :error="requests.form.state.errors.customer">
              <el-autocomplete
                v-model="state.form.customer"
                :fetch-suggestions="queryCustomer"
                :disabled="isDisabled"
                @select="handleCustomerSelect"
              />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="phone" label="Телефон" :error="requests.form.state.errors.phone">
              <el-input v-mask data-maska="+7 (###) ###-##-##" v-model="state.form.phone" :disabled="isDisabled" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="phone2" label="Телефон 2" :error="requests.form.state.errors.phone2">
              <el-input v-mask data-maska="+7 (###) ###-##-##" v-model="state.form.phone2" :disabled="isDisabled" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6" :xs="24">
            <el-form-item prop="address_city" label="Город" :error="requests.form.state.errors.address_city">
              <el-input v-model="state.form.address_city" :disabled="isDisabled" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="address_district" label="Район" :error="requests.form.state.errors.address_district">
              <el-input v-model="state.form.address_district" :disabled="isDisabled" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="address_street" label="Улица" :error="requests.form.state.errors.address_street">
              <el-input v-model="state.form.address_street" :disabled="isDisabled" />
            </el-form-item>
          </el-col>

          <el-col :span="2" :xs="8">
            <el-form-item prop="address_house" label="Дом" :error="requests.form.state.errors.address_house">
              <el-input v-model="state.form.address_house" :disabled="isDisabled" />
            </el-form-item>
          </el-col>

          <el-col :span="2" :xs="8">
            <el-form-item prop="address_building" label="Корпус" :error="requests.form.state.errors.address_building">
              <el-input v-model="state.form.address_building" :disabled="isDisabled" />
            </el-form-item>
          </el-col>

          <el-col :span="2" :xs="8">
            <el-form-item prop="address_room" label="Кв" :error="requests.form.state.errors.address_room">
              <el-input v-model="state.form.address_room" :disabled="isDisabled" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12" :xs="24">
            <el-form-item prop="address_extra" label="Адрес доп" :error="requests.form.state.errors.address_extra">
              <el-input v-model="state.form.address_extra" :disabled="isDisabled" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="salesman" label="Продавец" :error="requests.form.state.errors.salesman">
              <el-input v-model="state.form.salesman" :disabled="isDisabled" />
            </el-form-item>
          </el-col>
        </el-row>

        <price-attributes
          v-if="can('access_order_price_fields')"
          v-model:master_debt="state.form.master_debt"
          v-model:cost="state.form.cost"
          v-model:payment_date_shop="state.form.payment_date_shop"
          v-model:is_payment_immediately="state.form.is_payment_immediately"
          v-model:payment_part_master="state.form.payment_part_master"
          v-model:payment_date_master="state.form.payment_date_master"
          v-model:payment_part_shop="state.form.payment_part_shop"
          v-model:payment_part_extra="state.form.payment_part_extra"
          v-model:company_debt="state.form.company_debt"
          v-model:master_balance="state.form.master_balance"
          v-model:payment_part_master_extra="state.form.payment_part_master_extra"
          v-model:is_calculated_prices="state.form.is_calculated_prices"
          :status-id="state.form.status_id"
          :without-services="state.withoutServices"
          :services="services"
          :order-services="state.form.orderServices"
          :errors="requests.form.state.errors"
          :disabled="isDisabled"
        />

        <note-attributes
          v-model:note="state.form.note"
          v-model:note_inner="state.form.note_inner"
          :log-notes="state.logNotes"
          :disabled="isDisabled"
          :is-new="isNew"
          :errors="requests.form.state.errors"
        />

        <el-form-item label="Файлы">
          <order-file-upload v-model="state.form.files" :disabled="isDisabled" />
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="partner_id" :error="requests.form.state.errors.partner_id" label="Партнер">
              <el-select
                v-model="state.form.partner_id"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  v-for="option in partners"
                  :key="option.id"
                  :value="option.id"
                  :label="option.title"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <master-select
              v-model="state.form.master_id"
              :error="requests.form.state.errors.master_id"
              :location-id="shop?.location_id"
              :category-id="state.form.category_id"
            />
          </el-col>

          <el-col :span="8">
            <el-form-item prop="pre_master_id" :error="requests.form.state.errors.pre_master_id" label="Мастер предварительно">
              <master-autocomplete v-model="state.form.pre_master_id" clearable :disabled="isDisabled" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6" :xs="24">
            <el-form-item prop="status_id" :error="requests.form.state.errors.status_id" label="Статус">
              <el-select
                v-model="state.form.status_id"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="option in statuses"
                  :key="option.id"
                  :value="option.id"
                  :label="option.title"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item prop="is_doc_passed">
          <el-checkbox v-model="state.form.is_doc_passed" :disabled="isDisabled" label="Документы сданы" />
        </el-form-item>

        <el-form-item prop="has_debt">
          <el-checkbox v-model="state.form.has_debt" :disabled="isDisabled" label="Долг по основной сумме" />
        </el-form-item>

        <el-form-item prop="has_debt_extra">
          <el-checkbox v-model="state.form.has_debt_extra" :disabled="isDisabled" label="Долг по доп. сумме" />
        </el-form-item>

        <template v-if="state.form.orderServices?.length">
          <summary-table :items="tableServices" />
          <p>Итоговая стоимость: {{ totalCost }}</p>
        </template>

        <el-form-item class="hidden-xs-only">
          <el-button type="success" @click="submitForm">Сохранить</el-button>
          <el-button v-if="isNew" type="success" @click="submitFormPrint">Сохранить и распечатать</el-button>
          <el-button v-else-if="canCopy" type="primary" @click="submitCopy">Сделать копию заказа</el-button>
        </el-form-item>

      </el-form>

    </el-col>

    <el-col :span="12" :xs="24">
      <div v-loading="requests.services.state.pending">
        <div class="service-block-header">
          <h2>Услуги</h2>
          <el-button v-if="props.showClose" class="form-back-btn" @click="emit('close')">Назад</el-button>
        </div>

        <el-checkbox v-if="canCreateWithoutServices" label="Без услуг" v-model="state.withoutServices"/>

        <template v-if="!state.withoutServices">
          <service-table
            v-if="state.form.category_id"
            v-model="state.form.orderServices"
            :shop-id="state.form.shop_id"
            :order-type-id="state.form.type_id"
            :category-id="state.form.category_id"
            :services="services"
            show-hidden-services
          />

          <p v-else>Выберите категорию услуг</p>
        </template>
      </div>
    </el-col>
  </el-row>

  <el-form-item class="hidden-sm-and-up">
    <el-button type="success" @click="submitForm">Сохранить</el-button>
    <el-button type="success" @click="submitFormPrint">Сохранить и распечатать</el-button>
  </el-form-item>
</template>

<script setup lang="ts">
import { computed, ComputedRef, nextTick, reactive, ref, watch } from 'vue'
import {
  iOrder,
  iOrderService, iOrderServiceWithService,
  iOrderType,
  iService, iShopItem, iShopSettings
} from '@/types/models'
import useDataRequest from '@/composables/useDataRequest'
import { loadServicesByShopId } from '@/api/services'
import { copyOrder, getShopSettings, saveOrder } from '@/api/orders'
import ServiceTable from '@/components/orders/form/ServiceTable.vue'
import useFormRequest from '@/composables/useFormRequest'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import router from '@/router'
import { useStore } from '@/store'
import { iOrderManagerForm, Nullable } from '@/types/forms'
import useCustomerAutocomplete from '@/composables/useCustomerAutocomplete'
import OrderFileUpload from '@/components/orders/form/OrderFileUpload.vue'
import { formatCurrency } from '@/utils/format'
import moment from 'moment'
import pickerShortcuts from '@/utils/pickerShortcuts'
import { getOrderById } from '@/api/rest/restOrders'
import { iLogNoteWithUser } from '@/types/responses'
import SummaryTable from '@/components/orders/form/SummaryTable.vue'
import PriceAttributes from '@/components/orders/form/PriceAttributes.vue'
import NoteAttributes from '@/components/orders/form/NoteAttributes.vue'
import MasterAutocomplete from '@/components/masters/MasterAutocomplete.vue'
import MasterSelect from '@/components/orders/form/MasterSelect.vue'
import { vMaska as vMask } from 'maska'
import useOrderFormBehaviors from '@/composables/useOrderFormBehaviors'
import { noop } from 'lodash'
import ExpectedTimeSelect from '@/components/orders/form/ExpectedTimeSelect.vue'

interface StateInterface {
  services: Array<iService>
  shopSettings: iShopSettings
  currentStatus?: number
  form: Nullable<iOrderManagerForm>
  logNotes: iLogNoteWithUser[]
  withoutServices: boolean
}

const props = defineProps<{
  id?: number|null
  showClose?: boolean
}>()

const requests = {
  services: useDataRequest(),
  order: useDataRequest(),
  form: useFormRequest<iOrderManagerForm>()
}

const pending = computed(() => Object.keys(requests).find(key => requests[key as keyof typeof requests].state.pending) !== undefined)

const store = useStore()
const formRef = ref<FormInstance>()

const shops = computed(() => store.state.shops)

const state = reactive<StateInterface>({
  shopSettings: {
    expectedNumber: null,
    is_payment_in_shop: false
  },
  withoutServices: false,
  form: {
    id: null,
    cost: null,
    type_id: null,
    category_id: null,
    number: '',
    shop_id: shops.value.length === 1 ? shops.value[0].id : null,
    date_order: moment().format('YYYY-MM-DD'),
    date_expected: '',
    time_expected: '',
    delivery_time: '',
    date_assemble: '',
    date_call: '',
    ignore_expected_date: false,
    files: [],
    phone: '',
    phone2: '',
    salesman: '',
    customer: '',
    address_building: '',
    address_city: '',
    address_district: '',
    address_extra: '',
    address_house: '',
    address_room: '',
    address_street: '',
    orderServices: [],
    note: '',
    note_inner: '',
    is_payment_immediately: false,
    payment_part_shop: 0,
    payment_part_master: 0,
    payment_part_master_extra: 0,
    payment_part_extra: 0,
    payment_date_shop: null,
    payment_date_master: null,
    master_id: null,
    pre_master_id: null,
    is_doc_passed: false,
    has_debt: false,
    has_debt_extra: false,
    is_lying: false,
    is_urgent: false,
    master_debt: 0,
    company_debt: 0,
    master_balance: 0,
    partner_id: store.state.user.partner_id || null,
    status_id: 0,
    is_calculated_prices: true
  },
  logNotes: [],
  services: [],
  currentStatus: undefined
})

const preloadedServices = ref<iService[]>([])

const services = computed(() => {
  return Array.from((new Map([...preloadedServices.value, ...state.services].map(value => [value.id, value]))).values())
})

const {
  handleShopChange,
  handleCategoryChange,
  handleTypeChange
} = useOrderFormBehaviors(state.form)

const requiredField = [{ required: true, trigger: 'blur', message: 'Обязательно для заполнения' }]

const rules = computed<FormRules<iOrderManagerForm>>(() => {
  if (isAlreadyCompleted.value) {
    return {}
  }

  const items: FormRules<iOrderManagerForm> = {
    number: requiredField,
    shop_id: requiredField,
    type_id: requiredField,
    category_id: requiredField,
    customer: requiredField,
    phone: requiredField,
    address_city: requiredField,
    address_street: requiredField,
    address_house: requiredField,
    address_room: requiredField
  }

  if (state.form.date_expected && state.form.master_id) {
    const categoryText: string = categories.value?.find(value => value.value === state.form?.category_id)?.label as string ?? ''
    if (!categoryText.toLowerCase().includes('замерщик')) {
      items.time_expected = requiredField
    }
  }

  return items
})

const isNew = computed(() => !props.id)
const isDisabled = computed(() => !store.getters.can('order_update_completed') && state.form.status_id === 3)
const isAlreadyCompleted = computed(() => state.currentStatus === 3)
const title = computed(() => isNew.value ? 'Новая заявка' : 'Редактирование заявки №' + state.form.number)
const can = computed(() => store.getters.can)
const partners = computed(() => store.state.partners)
const statuses = computed(() => store.getters.statuses)
const canCreateWithoutServices = computed(() => store.getters.can('create_without_services'))
const canCopy = computed(() => store.getters.can('order_copy'))

const shopId = computed({
  get: () => state.form.shop_id,
  set: (value) => {
    state.form.shop_id = value
    loadServices()
  }
})

const emit = defineEmits(['close', 'save'])

const shop: ComputedRef<iShopItem|undefined> = computed<iShopItem|undefined>(() => shops.value.find(s => s.id === state.form.shop_id))
const types = computed<Array<iOrderType>>(() => store.state.orderTypes.filter(t => shop.value?.orderTypes.includes(t.id)))

const categories = computed(() => {
  let result: Record<string, string | number>[] = []
  if (types.value.length) {
    const typeObj = types.value.find(i => i.id === state.form.type_id)

    const filteredServices = state.form.type_id === null
      ? state.services
      : state.services.filter(service => service.order_type_id === state.form.type_id)

    const serviceCategories = filteredServices.map(i => i.category_id)

    result = result.concat(typeObj?.categories.filter(i => serviceCategories.includes(i.id)).map(i => ({
      value: i.id,
      label: i.title
    })) || [])
  }

  return result
})

const { queryCustomer, handleCustomerSelect } = useCustomerAutocomplete(state.form)

const save = async (print: boolean) => {
  formRef.value?.validate(async valid => {
    if (valid) {
      await requests.form.tryRequest(async () => {
        const response = await saveOrder(state.form as iOrderManagerForm)
        emit('save', response.data.id, print)
      })
    } else {
      ElMessage({
        message: 'Не все поля заполнены',
        type: 'warning'
      })
    }
  })
}

const submitForm = async () => {
  await save(false)
}

const submitFormPrint = async () => {
  await save(true)
}

const submitCopy = () => {
  requests.order.tryRequest(async () => {
    const response = await copyOrder(props.id as number)
    const id = response.data.id
    router.push({
      name: 'order-update',
      params: { id }
    }).then(noop)
  })
}

const loadServices = async () => {
  state.services = []
  state.form.orderServices = []

  if (state.form.shop_id !== null) {
    await requests.services.tryRequest(async () => {
      state.services = (await loadServicesByShopId({ shopId: state.form.shop_id as number })).data || []
    })

    await requests.services.tryRequest(async () => {
      state.shopSettings = (await getShopSettings(state.form.shop_id as number)).data || {
        expectedNumber: null,
        is_payment_in_shop: false
      }
      if (state.shopSettings.expectedNumber) {
        state.form.number = state.shopSettings.expectedNumber.toString()
      }

      state.form.is_payment_immediately = !state.shopSettings.is_payment_in_shop
    })

    if (types.value.length === 1) {
      state.form.type_id = types.value[0].id
    }

    await nextTick(() => {
      if (categories.value.length === 1) {
        state.form.category_id = parseInt(categories.value[0].value as string)
      }
    })
  }
}

const tableServices = computed(() => state.form.orderServices?.map<Partial<iService> & iOrderService>((value: iOrderServiceWithService) => {
  const service = value.service ?? state.services.find(i => i.id === value.service_id)
  return {
    ...service,
    ...value
  }
}))

const totalCost = computed(() => formatCurrency(state.form.orderServices?.reduce((carry, service) => carry + (service.price * (service.qty || 0)), 0) || 0))

interface iOriginalOrder extends iOrder {
  orderServices: iOrderServiceWithService[]
  logNotes: iLogNoteWithUser[]
}

watch(() => props.id, async () => {
  const requestParams = {
    params: {
      expand: ['orderServices', 'orderServices.service', 'logNotes', 'logNotes.user', 'files'].join(',')
    }
  }

  if (props.id !== null) {
    await requests.order.tryRequest(async () => {
      const response = (await getOrderById<iOriginalOrder>(props.id as number, requestParams)).data
      state.logNotes = response.logNotes
      state.services = (await loadServicesByShopId({ shopId: response.shop_id })).data
      state.currentStatus = response.status_id
      state.withoutServices = response.orderServices?.length === 0
      Object.assign(state.form, response)
      preloadedServices.value = state.form.orderServices?.map<iService>(os => os.service as iService) || []
    })
  }
}, { immediate: true })

const setExpectedTime = (value: string) => {
  state.form.time_expected = value
  formRef.value?.clearValidate('time_expected')
}

watch(() => state.withoutServices, () => {
  if (state.withoutServices) {
    state.form.is_calculated_prices = false
  }
})

watch(() => shop.value, () => {
  state.form.is_payment_immediately = (shop.value && !shop.value.is_payment_in_shop) ?? null
})
</script>

<style>
.time-select-wrapper .el-form-item__content {
  display: block;
}
</style>
