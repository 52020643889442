<template>
  <p>Выбрано услуг: {{ props.services.length }}</p>

  <el-alert type="info" show-icon :closable="false">
    Для формы редактирования заявки при копировании должна быть выбрана только одна услуга
  </el-alert>

  <el-form v-loading="requestWrapper.state.pending" inline label-position="top" style="margin-top: 20px;">
    <el-form-item label="Магазины">
      <multiple-select :options="shops" v-model="selectedShops" style="width: 250px" />
    </el-form-item>

    <el-form-item label="&nbsp;">
      <el-button type="success" :disabled="isDisabled" @click="handleCopy" title="Копирование выбранных услуг в другие магазины">Копировать</el-button>
    </el-form-item>
  </el-form>
</template>
<script setup lang="ts">
import MultipleSelect from '@/components/MultipleSelect.vue'
import { iService, iShopItem } from '@/types/models'
import { useStore } from '@/store'
import { ref, computed } from 'vue'
import useDataRequest from '@/composables/useDataRequest'
import { saveService } from '@/api/rest/restServices'
import { ElNotification } from 'element-plus'

const store = useStore()

const props = defineProps<{
  services: iService[]
}>()

const selectedShops = ref([])

const loading = ref(false)

const isDisabled = computed(() => selectedShops.value.length === 0)

const shops = computed<Array<iShopItem>>(() => store.state.shops)

const requestWrapper = useDataRequest()

const emit = defineEmits(['save'])

const handleCopy = async () => {
  try {
    loading.value = true

    for (const service of props.services) {
      for (const shopId of selectedShops.value) {
        await requestWrapper.tryRequest(async () => {
          await saveService({
            ...service,
            shop_id: shopId,
            id: null
          })
        })
      }
    }

    selectedShops.value = []
    emit('save')
  } catch (e) {
    ElNotification({
      title: 'Ошибка',
      message: 'Ошибка при копировании услуг',
      type: 'error'
    })
  } finally {
    loading.value = false
  }
}

</script>
