import { useRoute, useRouter } from 'vue-router'

const useSort = () => {
  const route = useRoute()
  const router = useRouter()

  const handleSort = (sort: {order: string|null, prop: string}) => {
    const query = {
      ...route.query
    }

    if (sort.order === null) delete query.sort
    else if (sort.order === 'ascending') query.sort = sort.prop
    else if (sort.order === 'descending') query.sort = '-' + sort.prop

    router.push({ query })
  }

  return {
    handleSort
  }
}

export default useSort
