import { loadFileData } from '@/utils/downloadFile'
import { downloadFileByUrl } from '@/api/files'

type iReplacer = {
  from: string
  to: string
}

// Загружаем изображения напрямую
const loadImages = async (block: string) => {
  const reg = /<img\s[^>]*src=['"]([^'"]+)['"][^>]*>/gi
  let result: RegExpExecArray | null
  const replacePromises: Promise<iReplacer>[] = []

  while ((result = reg.exec(block)) !== null) {
    const src = result[1]
    if (!src.startsWith('data:')) {
      replacePromises.push(new Promise((resolve, reject) => {
        loadFileData(downloadFileByUrl(src)).then(data => {
          resolve({
            from: src,
            to: data
          })
        }, reject)
      }))
    }
  }

  const replaces = await Promise.all(replacePromises)
  let text = block

  replaces.forEach(r => {
    text = text.replaceAll(r.from, r.to)
  })

  return text
}

// Ожидание отрисовки изображений
const preloadImages = async (block: HTMLElement) => {
  await Promise.all(Array.from(block.querySelectorAll('img')).map<Promise<void>>((img: HTMLImageElement) => {
    return new Promise((resolve, reject) => {
      if (img.complete && img.naturalHeight !== 0) {
        resolve() // Если изображение уже загружено
      } else {
        img.onload = () => resolve()
        img.onerror = reject
      }
    })
  }))
}

const printText = async (text: string) => {
  const body = document.querySelector('body')
  if (body) {
    const block = document.createElement('DIV')
    block.classList.add('print-block')
    block.innerHTML = await loadImages(text)
    body.append(block)

    await preloadImages(block)

    body.classList.add('print-mode')
    window.print()
    body.classList.remove('print-mode')
    block.remove()
  }
}

export default printText
