import instance from '@/api/rest/instance'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { DocumentTypeEnum, iDocument } from '@/types/models'
import { iRestCollection } from '@/types/responses'

export const documentUrls = {
  viewAttachment: (documentId: number, id: number) => instance.getUri({
    url: 'documents/' + documentId + '/attachments/' + id,
    params: { 'access-token': instance.defaults.headers.common['X-Api-Key'] }
  })
}

export default {
  get: (type: DocumentTypeEnum, params: AxiosRequestConfig = {}): AxiosPromise<iRestCollection<iDocument>> => {
    params.params = { ...(params.params || {}), type }
    return instance.get('documents', params)
  },
  getById: (id: number, params = {}): AxiosPromise<iDocument> => instance.get('documents/' + id, params),
  saveForm: (params: FormData) => {
    const id = params.get('id') || undefined
    if (id) return instance.putForm('documents/' + id, params)
    else return instance.postForm('documents', params)
  },
  generateServicesPrice: (id: number): AxiosPromise<void> => {
    return instance.post('documents/' + id + '/generate-services-price')
  },
  attachments: {
    delete: (documentId: number, id: number, params = {}) => instance.delete('documents/' + documentId + '/attachments/' + id, params)
  }
}
