import { domain, fileInstance } from '@/api/instance'
import { AxiosPromise } from 'axios'

export const downloadFile = (id: string|number): AxiosPromise<Blob> => fileInstance.get('files/download/' + id)
export const downloadFileByUrl = (url: string) => fileInstance.get<Blob>(url, { baseURL: domain })

export const downloadFileByUrlToBrowser = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.click()
  link.remove()
}
