<template>
  <el-row v-loading="request.state.pending" :gutter="20">
    <el-col :xs="24" :span="8">
      <el-form-item>
        <el-select v-model="filter.categories" placeholder="Категории услуг" multiple clearable filterable>
          <el-option-group v-for="group in orderTypes" :label="group.title" :key="group.id">
            <el-option v-for="item in group.categories" :label="item.title" :value="item.id.toString()" :key="item.id" />
          </el-option-group>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :xs="24" :span="8">
      <el-form-item>
        <el-select
          v-model="filter.masters"
          placeholder="Мастера"
          multiple
          clearable
          filterable
          remote
          :remote-method="remoteMastersList"
        >
          <el-option v-for="item in masters" :label="item.title" :value="item.id.toString()" :key="item.id"/>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :xs="24" :span="8">
      <el-form-item>
        <el-select v-model="filter.locations" placeholder="Локации" multiple clearable filterable>
          <el-option v-for="item in locations" :label="item.title" :value="item.id.toString()" :key="item.id"/>
        </el-select>
      </el-form-item>
    </el-col>

    <el-col :xs="24" :span="2">
      <el-button type="success" @click="handleSubmit">Применить</el-button>
    </el-col>

    <el-col :xs="24" :span="4">
      <el-form-item>
        <el-input v-model="filter.address" placeholder="Адрес" clearable/>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { useStore } from '@/store'
import { computed, watch, onMounted, ref } from 'vue'
import { iLocation, iMaster } from '@/types/models'
import restMasters from '@/api/rest/restMasters'
import { ElMessage } from 'element-plus'
import useDataRequest from '@/composables/useDataRequest'
import restLocations from '@/api/rest/restLocations'
import { iMasterPlanningFilter } from '@/types/forms'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps<{
  modelValue: iMasterPlanningFilter
  useUrl?: boolean
}>()
const emit = defineEmits(['update:modelValue'])

const store = useStore()
const route = useRoute()
const request = useDataRequest()
const router = useRouter()

const locations = ref<iLocation[]>([])
const masters = ref<iMaster[]>([])

const filter = ref<iMasterPlanningFilter>({ ...props.modelValue })

const orderTypes = computed(() => store.state.orderTypes)

const remoteMastersList = (masterTitle: string) => {
  const config = {
    params: {
      fields: ['id', 'title'].join(','),
      filter: {
        title: {
          like: masterTitle
        }
      }
    }
  }

  restMasters.get(config)
    .then(response => {
      masters.value = response.data.items
    })
    .catch(error => {
      ElMessage.error('Ошибка загрузки категорий')
      console.error(error)
    })
}

const handleSubmit = () => {
  emit('update:modelValue', { ...filter.value })
  if (props.useUrl) {
    router.push({ query: filter.value })
  }
}

watch(() => route.query, () => {
  if (props.useUrl) {
    Object.assign(filter.value, route.query)
  }
})

onMounted(() => {
  request.tryRequest(async () => {
    const response = await restLocations.list({ params: { fields: ['id', 'title'].join(',') } })
    const shopLocations = store.state.shops.map(shop => shop.location_id)
    locations.value = response.data.items.filter(location => shopLocations.includes(location.id))
  })
})
</script>
