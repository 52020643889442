<template>
  <el-form style="margin: 20px 0;" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="4" :xs="24">
        <el-form-item prop="status" label="Статус">
          <el-select v-model="state.filter.status" placeholder="Любой статус">
            <el-option value="" label="Любой статус" />
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col v-if="props.type === 'prices'" :span="4" :xs="24">
        <el-form-item prop="shop" label="Магазин">
          <el-select v-model="state.filter.shop"
                     style="width: 100%"
                     clearable
                     automatic-dropdown
                     filterable
          >
            <el-option v-for="item in shops" :key="item.id" :value="item.id" :label="item.title" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="4" :xs="24">
        <el-form-item prop="location" label="Локация">
          <el-select v-model="state.filter.location"
                     style="width: 100%"
                     clearable
                     automatic-dropdown
                     filterable
          >
            <el-option v-for="item in locations" :key="item.id" :value="item.id" :label="item.title" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="24">
        <el-form-item prop="category" label="Категория услуг">
          <el-select v-model="state.filter.category"
                     style="width: 100%"
                     clearable
                     automatic-dropdown
                     filterable
          >
            <el-option v-for="item in categories" :key="item.id" :value="item.id" :label="item.title" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="24" :xs="24">
        <el-form-item prop="title" label="Название">
          <el-input v-model="state.filter.title" clearable />
        </el-form-item>
      </el-col>
    </el-row>

    <el-button type="success" native-type="submit">Применить</el-button>
    <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
  </el-form>
</template>

<script lang="ts" setup>

import { computed, onMounted, reactive, ref, watch } from 'vue'

import { DocumentStatusEnum, DocumentTypeEnum, iCategory, iLocation, iShop } from '@/types/models'
import { useRoute, useRouter } from 'vue-router'
import documentStatusMap from '@/data/documentStatusMap'
import restLocations from '@/api/rest/restLocations'
import { ElMessage } from 'element-plus'
import restServiceCategories from '@/api/rest/restServiceCategories'
import restShops from '@/api/rest/restShops'

type iFilter = {
  title: string
  status: DocumentStatusEnum|undefined
  shop: number|string|undefined
  location: number|string|undefined
  category: number|string|undefined
}

export type iFilterValues = {
  // eslint-disable-next-line no-unused-vars
  [index in keyof iFilter]: string|undefined;
}

export type iQueryParams = iFilterValues

const props = defineProps<{
  type: DocumentTypeEnum
}>()
const emit = defineEmits(['loading'])

const router = useRouter()
const route = useRoute()

const state = reactive<{ filter: iFilter }>({
  filter: {
    title: '',
    status: undefined,
    shop: undefined,
    location: undefined,
    category: undefined
  }
})

const isFiltered = computed(() => route.query.title || route.query.status)

const statusOptions = computed(() => Object.entries(documentStatusMap).map(([value, item]) => {
  return {
    label: item.text,
    value: value
  }
}))

const shops = ref<iShop[]>([])
const locations = ref<iLocation[]>([])
const categories = ref<iCategory[]>([])

const getFilterQuery = (): iFilterValues => ({
  title: state.filter.title,
  status: state.filter.status?.toString() || '',
  shop: state.filter.shop?.toString() || '',
  location: state.filter.location?.toString() || '',
  category: state.filter.category?.toString() || ''
})

const doFilter = () => {
  const filterQuery: iFilterValues = getFilterQuery()
  const query: iQueryParams = { ...filterQuery }

  router.push({ query })
}

const doReset = () => {
  router.push({ query: {} })
}

const loadShops = () => restShops.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    shops.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })

const loadLocations = () => restLocations.list({ params: { fields: ['id', 'title', 'lat', 'lng'].join(',') } })
  .then(response => {
    locations.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })

const loadCategories = () => restServiceCategories.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    categories.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })

watch(() => route.query, () => {
  const filter = state.filter
  const query = route.query
  filter.title = query.title as string || ''
  filter.status = query.status as DocumentStatusEnum || undefined
  filter.shop = query.shop ? parseInt(query.shop as string) : undefined
  filter.location = query.location ? parseInt(query.location as string) : undefined
  filter.category = query.category ? parseInt(query.category as string) : undefined
}, { immediate: true })

onMounted(async () => {
  emit('loading', true)

  if (props.type === 'prices') {
    await loadShops()
  }
  await loadLocations()
  await loadCategories()

  emit('loading', false)
})

defineExpose({ getFilterQuery })

</script>
