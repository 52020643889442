import { nextTick, reactive, UnwrapRef } from 'vue'
import { ElNotification } from 'element-plus'
import { iFormErrors } from '@/types/responses'
import { CredentialsException, ErrorException, ValidationException } from '@/utils/exceptions'
import { iValidationErrorResponse } from '@/types/api'

type iFormRequest<T> = {
  pending: boolean
  success: boolean
  errors: iFormErrors<T>
}

const useFormRequest = <T>() => {
  type iFailOptions = {
    errors?: iFormErrors<T>
    message?: string
    showMessage: boolean
  }

  const state = reactive<iFormRequest<T>>({
    pending: false,
    success: false,
    errors: {} as iFormErrors<T>
  })

  return {
    state,

    start () {
      state.pending = true
      state.success = false
      state.errors = {} as UnwrapRef<iFormErrors<T>>
    },
    end () {
      state.pending = false
    },
    success () {
      state.success = true
      state.pending = false
    },
    fail (options: iFailOptions = {} as iFailOptions) {
      state.pending = false
      if (options.errors) {
        if (Array.isArray(options.errors)) {
          const errorMap = new Map()
          options.errors.forEach(item => {
            errorMap.set(item.field, item.message)
          })
          state.errors = Object.fromEntries(errorMap)
        } else {
          state.errors = options.errors as UnwrapRef<iFormErrors<T>>
        }
      } else {
        state.errors = {} as UnwrapRef<iFormErrors<T>>
      }

      if (options.showMessage && options.message) {
        ElNotification({
          title: 'Ошибка',
          message: options.message,
          type: 'error'
        })
      }
    },
    async tryRequest (callback: () => void): Promise<boolean> {
      try {
        this.start()
        await nextTick(callback)
        this.success()
        return true
      } catch (e: unknown) {
        if (e instanceof ValidationException) {
          this.fail({ errors: e.errors as iFormErrors<iValidationErrorResponse>, showMessage: false })
        } else if (e instanceof CredentialsException) {
          this.fail({ message: 'Необходима авторизация', showMessage: true })
        } else if (e instanceof ErrorException) {
          if (e.code >= 500) {
            this.fail({ message: 'Серверная ошибка', showMessage: true })
          } else {
            switch (e.code) {
              case 403:
                this.fail({ message: 'Действие запрещено', showMessage: true })
                break
              case 404:
                this.fail({ message: 'Объект не найден', showMessage: true })
                break
              default:
                this.fail({ message: 'Неправильные данные', showMessage: true })
            }
          }
        } else {
          console.error(e)
          this.fail({ message: 'Неизвестная ошибка', showMessage: true })
        }

        return false
      }
    }
  }
}

export default useFormRequest
