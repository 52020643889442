import { clearApiToken, loadUserState, setApiToken } from '@/api/auth'
import { ActionTree, createStore, GetterTree, MutationTree, Store, useStore as baseUseStore } from 'vuex'
import { CredentialsException } from '@/utils/exceptions'
import { iUserState } from '@/types/responses'
import { InjectionKey } from 'vue'
import { PermissionType } from '@/data/permissions'
import { iCategory, iFieldSet } from '@/types/models'
import { deleteFieldSet, setActiveFieldSet } from '@/api/orders'
import StatusMap from '@/data/statusMap'

interface StateType extends iUserState {
  token: string
}

const defaultState = <StateType> {
  user: {},
  role: {},
  shops: [],
  partners: [],
  orderTypes: [],
  orderFieldSets: [],
  statuses: [],
  token: ''
}

const state = <StateType> { ...defaultState }

const actions: ActionTree<StateType, StateType> = {
  async loginByToken ({ commit }, token?: string) {
    const cToken = (new Map<string, string>(
      document.cookie.split(';').map<Array<string>>(i => i.trim().split('=')) as [string, string][]
    )).get('crmToken')

    if (token === undefined) {
      if ((token = cToken) === undefined) {
        return false
      }
    }

    setApiToken(token)

    try {
      commit('setUserState', {
        ...(await loadUserState()).data as Omit<StateType, 'token'>,
        token
      })
    } catch (e) {
      if (!(e instanceof CredentialsException)) {
        console.error(e)
      }
      return false
    }
    document.cookie = `crmToken=${token};path=/;`
    return true
  },
  logout ({ commit }) {
    commit('clearUserState')
    document.cookie = 'crmToken=;path=/;'
    clearApiToken()
  },
  async deleteFieldSet ({ commit }, id: number) {
    await deleteFieldSet(id)
    commit('deleteFieldSet', id)
  },
  async setActiveFieldSet ({ commit }, id: number) {
    await setActiveFieldSet(id)
    commit('setActiveFieldSet', id)
  }
}

const mutations: MutationTree <StateType> = {
  clearUserState (state) {
    Object.assign(state, defaultState)
  },

  setUserState (state, userState: Partial<StateType>) {
    Object.assign(state, userState)
  },

  setActiveFieldSet (state, value: number|null) {
    const current = state.orderFieldSets.find(set => set.active)
    if (current) {
      current.active = 0
    }
    if (value) {
      const selected = state.orderFieldSets.find(set => set.id === value)
      if (selected) {
        selected.active = 1
      }
    }
  },

  updateFieldSet (state, model: iFieldSet) {
    const itemIndex = state.orderFieldSets.findIndex(set => set.id === model.id)
    if (itemIndex >= 0) {
      const newSettings = [...state.orderFieldSets]
      newSettings[itemIndex] = model
      state.orderFieldSets = newSettings
    } else {
      state.orderFieldSets.push(model)
    }
  },

  deleteFieldSet (state, id: number) {
    const itemIndex = state.orderFieldSets.findIndex(set => set.id === id)
    if (itemIndex >= 0) {
      state.orderFieldSets.splice(itemIndex, 1)
    }
  }

}

const getters: GetterTree<StateType, StateType> = {
  can: (state) =>
    (permission: PermissionType): boolean =>
      (state.user.isAdmin || state.role.permissions?.includes(permission)) || false,
  isUnauthorized: (state) => !state.token,
  categories: (state) => state.orderTypes.reduce<Array<iCategory>>((carry, item) => [...carry, ...item.categories], []),
  statuses: (state) => StatusMap.filter(status => Object.keys(state.statuses).includes(status.id.toString()))
}

type useStoreType = (injectKey?: InjectionKey<Store<StateType>> | string) => Store<StateType>

export const useStore = baseUseStore as useStoreType

export default createStore<StateType>({
  state,
  getters,
  actions,
  mutations
})
