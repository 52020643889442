<template>
  <div>
    <el-table
      :show-summary="props.showSummary"
      :data="tableData"
      :summary-method="getSummaries"
      table-layout="fixed"
      @cell-click="handlerClickCell"
      :row-style="data => data.row.is_active ? {} : { opacity: 0.4 }"
    >
      <el-table-column type="index" label="#" :index="indexMethod"/>

      <el-table-column v-if="props.canSelect" width="100">
        <template #default="scope">
          <el-button type="primary" size="small" @click="emit('select', scope.row.id)">Назначить</el-button>
        </template>
      </el-table-column>

      <el-table-column class-name="master-cell-wrap" header-align="center" :min-width="200">
        <template #header>
          <el-row justify="space-between">
            <el-link class="date-link" @click="$emit('change-period', prevMoment.format('YYYY-MM-DD'))">
              ←{{ prevMoment.format('DD.MM.YYYY') }}
            </el-link>
            <el-link class="date-link" @click="$emit('change-period', nextMoment.format('YYYY-MM-DD'))">
              {{ nextMoment.format('DD.MM.YYYY') }}→
            </el-link>
          </el-row>
        </template>

        <template #default="{ row }">
          <el-link
            v-if="props.showUndeterminedOrders && row.numUndeterminedOrders"
            class="undetermined-orders"
            :href="getUndeterminedOrdersPath(row.id)"
            type="danger"
            target="_blank"
          >{{ row.numUndeterminedOrders }}
          </el-link>
          <el-link :href="'/masters/' + row.id" target="_blank">{{ row.title }}</el-link>
        </template>
      </el-table-column>

      <el-table-column class-name="master-cell-wrap" header-align="center" :min-width="200">
        <template #header>
          Адрес
        </template>

        <template #default="{ row }">
          {{ row.address }}
        </template>
      </el-table-column>

      <el-table-column
        v-for="tableDay in tableDays"
        :key="tableDay.day"
        :other-data="tableDay"
        :class-name="getDayCellClass(tableDay)"
        header-align="center"
        align="center"
        prop="day"
      >
        <template #header>
          {{ tableDay.formattedDay }}
        </template>

        <template #default="{ row }">
          <el-icon
            v-if="row.weekends.find(weekend => weekend.date.slice(-2) === tableDay.formattedDay)"
            :size="16"
            color="var(--el-color-danger)"
          >
            <Close/>
          </el-icon>
          <el-link
            v-if="row.numActiveOrdersByDate[tableDay.formattedDate]"
            :href="getActiveOrdersPath(row.id, tableDay.formattedDate)"
            target="_blank"
          >{{ row.numActiveOrdersByDate[tableDay.formattedDate] }}
          </el-link>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script setup lang="ts">

import { Close } from '@element-plus/icons-vue'
import { iMaster } from '@/types/models'
import { TableColumnCtx } from 'element-plus'
import { computed, h, VNode } from 'vue'
import { orderPaymentIds } from '@/data/orderPayment'
import { orderStatusIds } from '@/data/orderStatus'
import moment from 'moment/moment'
import { useRouter } from 'vue-router'

interface TableRowData extends iMaster {
  numUndeterminedOrders: number
  numActiveOrdersByDate: {
    [K: string]: number
  }
}

interface SummaryMethodProps<T = TableRowData> {
  columns: TableColumnCtx<T>[]
  data: T[]
}

interface iTableDay {
  day: number
  formattedDay: string
  formattedDate: string
  isSaturday: boolean
  isSunday: boolean
}

const props = defineProps<{
  items: iMaster[]
  startDate: string
  daysCount: number
  showUndeterminedOrders?: boolean
  showSummary?: boolean
  canSelect?: boolean
}>()

const emit = defineEmits(['select', 'click-cell', 'change-period'])

const router = useRouter()

const nullMoment = moment(0)
const startMoment = computed(() => moment(props.startDate))
const prevMoment = computed(() => moment(props.startDate).subtract(props.daysCount, 'd'))
const nextMoment = computed(() => moment(props.startDate).add(props.daysCount, 'd'))

const tableDays = computed<iTableDay[]>(() => Array(props.daysCount).fill(undefined).map((nullVal: null, index: number) => {
  const needMoment = startMoment.value.clone().add(index, 'd')
  const monthDay = needMoment.date()
  const weekDay = needMoment.day()

  return {
    day: monthDay,
    formattedDay: ('0' + monthDay).slice(-2),
    formattedDate: needMoment.format('YYYY-MM-DD'),
    isSaturday: weekDay === 6,
    isSunday: weekDay === 0
  }
}))
const tableData = computed<TableRowData[]>(() => props.items.map(item => {
  const numActiveOrdersByDate: {
    [K: string]: number
  } = {}

  tableDays.value.forEach(tableDay => {
    numActiveOrdersByDate[tableDay.formattedDate] = item.orders.filter(order => order.date_expected === tableDay.formattedDate).length
  })

  return {
    ...item,
    numUndeterminedOrders: item.orders.filter(
      order => !order.date_expected && !order.date_assemble
    ).length,
    numActiveOrdersByDate
  }
}))

const handlerClickCell = (row: TableRowData, column: TableColumnCtx<TableRowData>) => {
  if (column.property === 'day') {
    const day = column.rawColumnKey as unknown as number
    const formattedDate = tableDays.value.find(tableDay => tableDay.day === day)?.formattedDate

    if (!row.numActiveOrdersByDate[formattedDate as string]) {
      emit('click-cell', row.id, formattedDate)
    }
  }
}

const indexMethod = (index: number) => (index + 1)

const getSummaries = (param: SummaryMethodProps) => {
  const { columns, data } = param
  const sums: Array<string | number | VNode> = []
  let indexDay = 0

  columns.forEach(column => {
    if (column.type === 'index') {
      sums.push('')
      return
    }

    if (column.no === 1) {
      const vNodeNum = h(
        'span',
        { class: 'undetermined-orders', style: { color: 'var(--el-color-danger)' } },
        data.reduce(
          (accumulator, row) => accumulator + row.numUndeterminedOrders,
          0
        )
      )

      sums.push(h('span', [vNodeNum, 'Итого:']))
      return
    }

    if (column.property === 'day') {
      sums.push(data.reduce(
        (accumulator, row) => accumulator + (row.numActiveOrdersByDate[tableDays.value[indexDay].formattedDate] || 0),
        0
      ))
      indexDay++
      return
    }

    sums.push('')
  })

  return sums
}

const getDayCellClass = (tableDay: iTableDay) => {
  const resultArr = []

  if (tableDay.isSunday) {
    resultArr.push('sunday-cell')
  } else if (tableDay.isSaturday) {
    resultArr.push('saturday-cell')
  }

  return resultArr.join(' ')
}
const getActiveOrdersPath = (masterId: number, date: string) => router.resolve({
  name: 'orders',
  query: {
    dateExpected: date + ':' + date,
    masterId,
    payments: [1, 2].join(',')
  }
}).fullPath
const getUndeterminedOrdersPath = (masterId: number) => {
  const nullDate = nullMoment.format('YYYY-MM-DD')
  const nullDateRange = nullDate + ':' + nullDate

  return router.resolve({
    name: 'orders',
    query: {
      dateExpected: nullDateRange,
      dateAssemble: nullDateRange,
      masterId,
      payments: [orderPaymentIds.onSpot, orderPaymentIds.inShopAndPaid].join(','),
      statuses: [orderStatusIds.new, orderStatusIds.inWork].join(',')
    }
  }).fullPath
}
</script>

<style scoped lang="scss">

@media (min-width: 768px) {
  .table-container {
    div {
      overflow: visible;
    }

    .el-scrollbar__bar {
      display: none !important;
    }
  }
}

.date-link {
  text-decoration: none;
  border-bottom: 1px dotted;
  color: inherit;
}

:deep(.el-table) {
  tbody .master-cell-wrap .cell,
  tfoot .master-cell-wrap .cell {
    padding-left: 30px;

    .undetermined-orders {
      position: absolute;
      left: 15px;
      transform: translateX(-50%);
    }
  }

  .sunday-cell, .saturday-cell {
    background-color: var(--el-color-info-light-5);
  }
}
</style>
