<template>
  <div class="chart-container">
    <Line :data="chartData" :options="chartOptions" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartData,
  TimeScale,
  ChartOptions,
  Plugin
} from 'chart.js'
import moment from 'moment'
import 'chartjs-adapter-moment'
import { iChartItem } from '@/types/models'

// Регистрируем необходимые компоненты Chart.js
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale
)

const props = defineProps<{
  data: iChartItem[]
}>()

// Плагин для отображения значений на точках
const dataLabelsPlugin: Plugin<'line'> = {
  id: 'dataLabels',
  afterDatasetsDraw (chart) {
    const { ctx, data } = chart

    ctx.font = 'bold 12px Arial'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'bottom'
    ctx.fillStyle = '#666'

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex)

      meta.data.forEach((point, index) => {
        const value = data.datasets[datasetIndex].data[index]
        const x = point.x
        const y = point.y - 10

        ctx.fillText(value?.toString() || '', x, y)
      })
    })
  }
}

const chartData = computed<ChartData<'line'>>(() => ({
  labels: props.data.map((item) => moment(item.date, 'YYYY-MM-DD').toDate()),
  datasets: [
    {
      label: 'Кол-во заявок',
      data: props.data.map((item) => item.value),
      fill: 'start',
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.4)',
      pointBackgroundColor: 'rgba(75, 192, 192, 1)',
      pointRadius: 5,
      pointHoverRadius: 7,
      tension: 0.1
    }
  ]
}))

const chartOptions = ref<ChartOptions<'line'>>({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'top'
    },
    tooltip: {
      enabled: true
    }
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        tooltipFormat: 'DD MMM',
        displayFormats: {
          day: 'DD MMM'
        }
      },
      title: {
        display: true,
        text: 'Дата оформления заказа',
        font: {
          weight: 'bold'
        }
      }
    },
    y: {
      title: {
        display: true,
        text: 'Кол-во заявок',
        font: {
          weight: 'bold'
        }
      },
      min: 0,
      beginAtZero: true,
      suggestedMax: computed(() => {
        // Автоматически вычисляем максимальное значение + 10-20% отступа
        const maxValue = Math.max(...props.data.map(item => item.value))
        return Math.ceil(maxValue * 1.15) // Добавляем 15% отступа сверху
      }),
      ticks: {
        callback: (value) => Number.isInteger(value) ? value : null,
        stepSize: 1,
        font: {
          family: 'Arial'
        }
      }
    }
  }
})

// Добавляем наш плагин в конфигурацию
ChartJS.register(dataLabelsPlugin)
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 400px;
}
</style>
