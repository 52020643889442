import { DocumentTypeEnum } from '@/types/models'

type iDocumentTypeMapItem = {
  text: string
  type: string
}

const documentTypeMap: Record<DocumentTypeEnum, iDocumentTypeMapItem> = {
  standards: {
    text: 'Стандарты',
    type: 'info'
  },
  acts: {
    text: 'Акты',
    type: ''
  },
  prices: {
    text: 'Прайсы',
    type: 'success'
  }
}

export default documentTypeMap
