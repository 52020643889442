<template>
  <div>
    <el-button @click="handleCreate">
      Новый мастер
    </el-button>
    <master-index ref="indexRef" @edit-record="handleEdit"/>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LocationIndex from '@/components/locations/LocationIndex.vue'
import MasterIndex from '@/components/masters/MasterIndex.vue'

const router = useRouter()
const route = useRoute()

const indexRef = ref<InstanceType<typeof LocationIndex>>(null)

const handleCreate = () => {
  handleEdit('new')
}
const handleEdit = (id: number|'new') => {
  router.push({
    name: 'master-update',
    params: {
      id
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}

</script>
