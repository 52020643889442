import { RouteParamsRaw, useRoute, useRouter } from 'vue-router'

const useBackNavigation = (routeName: string) => {
  const router = useRouter()
  const route = useRoute()

  return (params?: RouteParamsRaw) => {
    router.push(route.query.returnUrl !== null
      ? {
          name: routeName,
          params,
          query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
        }
      : {
          name: routeName,
          params
        })
  }
}

export default useBackNavigation
