<template>
  <div v-loading="state.loading">
    <el-table
      ref="tableRef"
      :data="tableData"
      empty-text="Ничего не нашлось"
      table-layout="auto"
    >
      <el-table-column label="Действия" header-align="center" align="center">
        <template #default="scope">
          <el-button type="success" :icon="EditPen" circle @click="$emit('editRecord', scope.row.id)"/>
        </template>
      </el-table-column>

      <el-table-column label="ID" align="center" header-align="center">
        <template #default="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Тип">
        <template #default="scope">
          <span>{{ documentTypeMap[scope.row.type].text }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Название">
        <template #default="scope">
          <span>{{ scope.row.title || 'НАЗВАНИЕ НЕ ЗАДАНО' }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="type === 'prices'" label="Магазин">
        <template #default="scope">
          <el-tag disable-transitions type="info">{{ scope.row.shop.title }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="Локации">
        <template #default="scope">
          <el-tag v-for="item in scope.row.locations.slice(0, 3)" :key="item.id" disable-transitions type="info">{{ item.title }}</el-tag>
          <el-tag v-if="scope.row.locations.length > 3" disable-transitions type="info">+ {{ scope.row.locations.length - 3 }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="Категории">
        <template #default="scope">
          <el-tag v-for="item in scope.row.categories.slice(0, 3)" :key="item.id" disable-transitions type="info">{{ item.title }}</el-tag>
          <el-tag v-if="scope.row.categories.length > 3" disable-transitions type="info">+ {{ scope.row.categories.length - 3 }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="Статус" align="center" header-align="center">
        <template #default="scope">
          <el-tag :type="documentStatusMap[scope.row.status].type" :title="documentStatusMap[scope.row.status].tooltip" disable-transitions>{{ documentStatusMap[scope.row.status].text || 'Неизвестно' }}</el-tag>
        </template>
      </el-table-column>

    </el-table>

    <el-row justify="end">
      <el-pagination background hide-on-single-page layout="prev, pager, next" :total="state._meta.totalCount" :page-size="state._meta.perPage" v-model:current-page="state._meta.currentPage" :page-count="state._meta.pageCount" @change="doFilter" />
    </el-row>
  </div>
</template>

<script setup lang="ts">

import { computed, reactive, ref, watch } from 'vue'
import { DocumentTypeEnum, iDocument } from '@/types/models'
import { useRoute } from 'vue-router'
import { EditPen } from '@element-plus/icons-vue'
import { ElMessage, TableInstance } from 'element-plus'
import restDocuments from '@/api/rest/restDocuments'
import router from '@/router'
import documentStatusMap from '@/data/documentStatusMap'
import documentTypeMap from '@/data/documentTypeMap'

interface iFilter {
  search: string
}

const props = defineProps<{
  type: DocumentTypeEnum
}>()
defineEmits(['editRecord'])

const route = useRoute()
const tableRef = ref<TableInstance>()
const state = reactive<{
  filter: iFilter
  items: Array<iDocument>
  loading: boolean
  _meta: {
    totalCount: number
    pageCount: number
    currentPage: number
    perPage: number
  }
}>({
  loading: true,
  filter: {
    search: route.query?.search as string || ''
  },
  items: [],
  _meta: {
    totalCount: 0,
    pageCount: 0,
    currentPage: Number(route.query.page) || 1,
    perPage: Number(route.query.perPage) || 20
  }
})

const tableData = computed(() => state.items)

const load = () => {
  const queryParams = {
    params: {
      fields: ['id', 'type', 'title', 'locations.id', 'locations.title', 'categories.id', 'categories.title', 'shop.id', 'shop.title', 'status'].join(','),
      expand: ['locations', 'categories', 'shop'].join(','),
      ...route.query
    }
  }

  state.loading = true

  restDocuments.get(props.type, queryParams)
    .then(response => {
      state.items = response.data.items
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}

const doFilter = () => {
  const query = {
    ...state.filter,
    page: state._meta.currentPage,
    perPage: state._meta.perPage
  }

  router.push({ query })
}

defineExpose({
  load
})

watch(() => route.query, () => {
  load()
}, { immediate: true })

</script>
