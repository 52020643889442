<template>
  <el-form-item prop="note" :error="props.errors.note">
    <template #label>
      Примечания
      <el-button
        v-if="!isNew"
        :icon="Memo"
        size="small"
        title="История изменений"
        type="primary"
        circle
        @click="activeNoteHistory = 'note'"
      />
    </template>
    <el-input
      type="textarea"
      :disabled="disabled"
      :model-value="props.note"
      @update:model-value="e => $emit('update:note', e)"
    />
  </el-form-item>

  <el-form-item prop="note_inner" :error="props.errors.note_inner">
    <template #label>
      Внутренние примечания
      <el-button
        v-if="!isNew"
        :icon="Memo"
        size="small"
        title="История изменений"
        type="primary"
        circle
        @click="activeNoteHistory = 'note_inner'"
      />
    </template>
    <el-input
      type="textarea"
      :model-value="props.note_inner"
      @update:model-value="e => $emit('update:note_inner', e)"
    />
  </el-form-item>

  <el-dialog v-model="isNoteHistoryVisible" :title="historyDialogTitle">
    <note-logs :items="logs" />
  </el-dialog>
</template>

<script lang="ts" setup>

import { iOrderNoteFields } from '@/types/forms'
import { iFormErrors, iLogNoteWithUser } from '@/types/responses'
import { computed, ref } from 'vue'
import { iLogNoteType } from '@/types/models'
import NoteLogs from '@/components/orders/form/NoteLogs.vue'
import { Memo } from '@element-plus/icons-vue'

interface iProps extends iOrderNoteFields {
  isNew: boolean
  errors: iFormErrors<iOrderNoteFields>
  logNotes: iLogNoteWithUser[]
  disabled: boolean
}

const props = defineProps<iProps>()

const activeNoteHistory = ref<iLogNoteType | undefined>()

const historyDialogTitle = computed(() => activeNoteHistory.value === 'note_inner' ? 'Внутренние примечания' : 'Примечания')

const logs = computed<iLogNoteWithUser[]>(() => {
  const logs = props.logNotes.filter((log) => {
    return log.type === activeNoteHistory.value
  }) ?? []
  logs.sort((a, b) => a.id - b.id)
  return logs
})

const isNoteHistoryVisible = computed({
  get: () => activeNoteHistory.value !== undefined,
  set: (value) => {
    if (!value) {
      activeNoteHistory.value = undefined
    }
  }
})

</script>
