<template>
  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
    @submit.prevent="submitForm"
  >
    <div class="form-title">
      <h2>Редактирование типа документа {{ recordTitle }}</h2>

      <el-button @click="$emit('close')">Назад</el-button>
    </div>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="title" label="Наименование" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="uploadImage" label="Изображение" :error="state.errors.uploadImage">
          <el-upload class="avatar-uploader"
                     name="uploadImage"
                     v-model="state.form.uploadImage"
                     @change="handleUploadImageChange"
                     :show-file-list="false"
                     :auto-upload="false">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        native-type="submit"
        type="success"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { ElMessage, FormInstance, FormRules, UploadUserFile } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { iBaseFormState, iDocumentTypeForm } from '@/types/forms'
import { DocumentTypeEnum, iDocumentType } from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restDocumentTypes, { documentTypeUrls } from '@/api/rest/restDocumentTypes'

type StateInterface = iBaseFormState<iDocumentTypeForm & { uploadImage: UploadUserFile|undefined }> & { loading: boolean, errors: Record<string, string> }

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordType: DocumentTypeEnum
}>()

const formRef = ref<FormInstance>()
const recordTitle = ref('')
const image = ref<string>('')
const imageUrl = computed(() => {
  return state.form.uploadImage ? URL.createObjectURL(state.form.uploadImage) : image.value
})

const state = reactive<StateInterface>({
  loading: false,
  form: {
    type: 'standards',
    title: '',
    image: undefined,
    uploadImage: undefined
  },
  errors: {}
})

const rules = computed<FormRules<iDocumentType>>(() => {
  const rules = {} as FormRules<iDocumentType>

  const fields: Array<keyof iDocumentType> = ['type', 'title']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  return rules
})

const handleUploadImageChange = (data: { raw?: File }) => {
  if (data.raw) {
    state.form.uploadImage = data.raw
  }
}

const loadForm = (type: DocumentTypeEnum) => {
  state.loading = true

  restDocumentTypes.getByType(type, {
    params: {
      fields: ['type', 'title'],
      expand: ['image'].join(',')
    }
  })
    .then(response => {
      const responseModel = response.data

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iDocumentTypeForm

        if (!(key in responseModel)) continue

        state.form[key] = responseModel[key]
      }

      if (responseModel.image) {
        image.value = documentTypeUrls.viewImage(responseModel.type as DocumentTypeEnum, { t: responseModel.image.created_at })
      }

      recordTitle.value = responseModel.title
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const form = new FormData()
  for (const key in { ...state.form }) {
    form.append(key, state.form[key])
  }
  form.delete('image')

  try {
    const response = await restDocumentTypes.saveForm(form)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}

onMounted(async () => {
  loadForm(props.recordType)
})

</script>

<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
