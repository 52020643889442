import { computed } from 'vue'
import { useStore } from '@/store'

const useCanUpdatePaymentDate = () => {
  const store = useStore()

  const canUpdatePaymentDateMaster = computed(() => store.getters.can('order_form_update_payment_date_master'))
  const canUpdatePaymentDateShop = computed(() => store.getters.can('order_form_update_payment_date_shop'))

  return {
    canUpdatePaymentDateMaster,
    canUpdatePaymentDateShop
  }
}

export default useCanUpdatePaymentDate
