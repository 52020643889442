import moment from 'moment'

export default {
  pastRange: [
    { text: 'Сегодня', value: () => [new Date(), new Date()] },
    { text: 'Вчера', value: () => [moment().subtract(1, 'd').toDate(), moment().subtract(1, 'd').toDate()] },
    { text: 'Последние 7 дней', value: () => [moment().subtract(6, 'd').toDate(), new Date()] },
    { text: 'Последние 30 дней', value: () => [moment().subtract(29, 'd').toDate(), new Date()] },
    { text: '2-я половина', value: () => [moment().subtract(1, 'month').date(16).toDate(), moment().subtract(1, 'month').endOf('month').toDate()] },
    { text: '1-я половина', value: () => [moment().date(1).toDate(), moment().date(15).toDate()] },
    { text: 'Текущий месяц', value: () => [moment().startOf('month').toDate(), moment().endOf('month').toDate()] },
    { text: 'Предыдущий месяц', value: () => [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()] },
    { text: 'Без даты', value: () => [moment(0).toDate(), moment(0).toDate()] }
  ],
  orderForm: [
    { text: 'Сегодня', value: () => new Date() },
    { text: 'Завтра', value: () => moment().add(1, 'd').toDate() },
    { text: 'Через неделю', value: () => moment().add(1, 'w').toDate() },
    { text: 'Через 2 недели', value: () => moment().add(2, 'w').toDate() },
    { text: 'Без даты', value: () => moment(0).toDate() }
  ]
}
