<template>
  <el-form-item class="master_id-form_item" prop="master_id" :error="error">
    <template #label>
      Мастер
      <el-button class="select_master-link" @click="showDialog = true" type="warning" link>
        Подобрать
      </el-button>
    </template>

    <master-autocomplete
      :model-value="props.modelValue"
      @update:model-value="handleSelect"
      clearable
      :disabled="props.disabled"
      :category-id="props.categoryId"
      :location-id="props.locationId"
    />
  </el-form-item>

  <el-dialog v-model="showDialog" width="90%">
    <master-planning-index
      :category-id="props.categoryId"
      :location-id="props.locationId"
      :address="props.address"
      can-select
      @select="handleSelect"
    />
  </el-dialog>
</template>

<script lang="ts" setup>

import { ref } from 'vue'
import MasterAutocomplete from '@/components/masters/MasterAutocomplete.vue'
import MasterPlanningIndex from '@/components/masterPlanning/MasterPlanningIndex.vue'

const props = defineProps<{
  disabled?: boolean
  modelValue: number|undefined|null
  error?: string
  categoryId: number|undefined|null
  locationId: number|undefined|null
  address?: string
}>()

const emit = defineEmits(['update:modelValue'])

const showDialog = ref(false)

const handleSelect = (value: number | undefined) => {
  emit('update:modelValue', value)
  showDialog.value = false
}

</script>
