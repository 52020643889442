<template>
  <div v-loading="request.state.pending">
  <p>
    {{ pluralize(ordersCount, ['Выбран', 'Выбрано', 'Выбрано']) }}
    {{ ordersCount }}
    {{ pluralize(ordersCount, ['заказ', 'заказа', 'заказов']) }}
  </p>
  <el-form :model="form" ref="formRef" label-width="200">
    <el-form-item v-if="canUpdatePaymentDateShop" prop="payment_date_shop" label="Дата оплаты магазину" :error="request.state.errors.payment_date_shop">
      <el-date-picker
        v-model="form.payment_date_shop"
        value-format="YYYY-MM-DD"
        format="DD.MM.YYYY"
        clearable
      />
    </el-form-item>
    <el-form-item v-if="canUpdatePaymentDateMaster" prop="payment_date_master" label="Дата оплаты мастеру" :error="request.state.errors.payment_date_master">
      <el-date-picker
        v-model="form.payment_date_master"
        value-format="YYYY-MM-DD"
        format="DD.MM.YYYY"
        clearable
      />
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="handleSave">Сохранить</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import { iBulkUpdateForm } from '@/types/forms'
import useFormRequest from '@/composables/useFormRequest'
import { bulkSave } from '@/api/orders'
import { pluralize } from '@/utils/format'
import useCanUpdatePaymentDate from '@/composables/useCanUpdatePaymentDate'

const props = defineProps<{
  ids: number[]
}>()

const { canUpdatePaymentDateMaster, canUpdatePaymentDateShop } = useCanUpdatePaymentDate()

const emit = defineEmits(['save'])

const ordersCount = computed(() => props.ids.length)

const request = useFormRequest<iBulkUpdateForm>()

const formRef = ref()

const form = reactive<Omit<iBulkUpdateForm, 'orders'>>({
  payment_date_master: '',
  payment_date_shop: ''
})

const handleSave = () => {
  request.tryRequest(async () => {
    await bulkSave({ ...form, orders: props.ids })
    formRef.value?.resetFields()
    emit('save')
  })
}
</script>
