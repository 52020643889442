<template>
  <div>
    <h2>{{ documentTypeMap[type].text }}</h2>
    <el-divider />

    <div class="form-title">
      <el-button @click="handleCreate" type="warning">
        Новый документ
      </el-button>
      <el-button @click="handleBack">Назад</el-button>
    </div>

    <el-divider />
    <documents-filter :type="type" />
    <el-divider />

    <documents-index ref="indexRef" :type="type" @edit-record="handleEdit" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import documentTypeMap from '@/data/documentTypeMap'
import DocumentTypesIndex from '@/components/documents/DocumentTypesIndex.vue'
import DocumentsIndex from '@/components/documents/DocumentsIndex.vue'
import useBackNavigation from '@/composables/useBackNavigation'
import DocumentsFilter from '@/components/documents/DocumentsFilter.vue'
import { DocumentTypeEnum } from '@/types/models'

const router = useRouter()
const route = useRoute()
const indexRef = ref<InstanceType<typeof DocumentTypesIndex>>(null)
const navigateBack = useBackNavigation('document-types')

const type = computed(() => String(route.params.type) as DocumentTypeEnum)

const handleBack = () => {
  navigateBack()
}
const handleCreate = () => {
  handleEdit('new')
}
const handleEdit = (id: number|'new') => {
  router.push({
    name: 'document-update',
    params: {
      type: route.params.type,
      id
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}
</script>
