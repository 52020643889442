import { iOrderSellerForm, Nullable } from '@/types/forms'

const useOrderFormBehaviors = (form: Nullable<iOrderSellerForm>) => {
  const handleShopChange = () => {
    form.type_id = null
    handleTypeChange()
  }

  const handleCategoryChange = () => {
    form.orderServices = []
  }

  const handleTypeChange = () => {
    form.category_id = null
    handleCategoryChange()
  }

  return {
    handleShopChange,
    handleCategoryChange,
    handleTypeChange
  }
}

export default useOrderFormBehaviors
