<template>
  <el-form inline label-position="top" @submit.prevent="handleSubmit">
    <el-form-item label="Мастер">
      <multiple-select :options="state.masters" v-model="filter.id" style="min-width: 250px" />
    </el-form-item>

    <el-form-item label="Тип">
      <el-select v-model="filter.type" style="min-width: 150px" clearable>
        <el-option value="thursday" label="Четверг" />
        <el-option value="thursday_payment" label="Оплата четверг" />
        <el-option value="wednesday" label="Среда" />
        <el-option value="wednesday_payment" label="Оплата среда" />
        <el-option value="tuesday" label="Вторник" />
        <el-option value="tuesday_payment" label="Оплата вторник" />
        <el-option value="ip" label="ИП" />
      </el-select>
    </el-form-item>

    <el-form-item label="&nbsp;">
      <el-button native-type="submit" type="success">Применить</el-button>
      <el-button v-if="isFiltered" type="warning" @click="handleReset">Сбросить</el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>

import { computed, watch, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import MultipleSelect from '@/components/MultipleSelect.vue'
import { iMultipleSelectOption } from '@/types/forms'
import { loadMasters } from '@/api/debtors'

const route = useRoute()
const router = useRouter()

const isFiltered = computed(() => route.query.type !== undefined)

const filter = reactive<{
  id: number[]
  type: string
}>({
  id: [],
  type: ''
})

const handleReset = () => {
  router.push({ query: {} })
}

const handleSubmit = () => {
  router.push({
    query: {
      ...filter,
      id: filter.id.join(',')
    }
  })
}

const state = reactive<{
  loading: boolean
  masters: iMultipleSelectOption[]
}>({
  loading: true,
  masters: []
})

const updateMasters = () => {
  state.loading = true
  loadMasters(filter).then(response => {
    state.loading = false
    state.masters = response.data
  })
}

watch(() => route.query, () => {
  const query = route.query
  filter.id = query.id as string ? (query.id as string).split(',').map(i => parseInt(i)) : []
  filter.type = query.type as string || ''
}, { immediate: true })

watch(() => route.query.type, () => {
  updateMasters()
}, { immediate: true })

</script>
