import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DashboardPage from '@/pages/DashboardPage.vue'
import OrdersPage from '@/pages/OrdersPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import UsersPage from '@/pages/UsersPage.vue'
import ShopsPage from '@/pages/ShopsPage.vue'
import LocationsPage from '@/pages/LocationsPage.vue'
import ServiceCategoriesPage from '@/pages/ServiceCategoriesPage.vue'
import PartnersPage from '@/pages/PartnersPage.vue'
import MasterPlanningPage from '@/pages/MasterPlanningPage.vue'
import MastersPage from '@/pages/MastersPage.vue'
import OrderFormPage from '@/pages/OrderFormPage.vue'
import StatisticPage from '@/pages/StatisticPage.vue'
import HistoryPage from '@/pages/HistoryPage.vue'
import RolesPage from '@/pages/RolesPage.vue'
import DebtorsPage from '@/pages/DebtorsPage.vue'
import TasksPage from '@/pages/TasksPage.vue'
import ServicesPage from '@/pages/ServicesPage.vue'
import PartnersDataPage from '@/pages/PartnersDataPage.vue'
import TemplatesPage from '@/pages/TemplatesPage.vue'
import UserFormPage from '@/pages/UserFormPage.vue'
import PartnerFormPage from '@/pages/PartnerFormPage.vue'
import MasterFormPage from '@/pages/MasterFormPage.vue'
import LocationFormPage from '@/pages/LocationFormPage.vue'
import ShopFormPage from '@/pages/ShopFormPage.vue'
import ServiceCategoryFormPage from '@/pages/ServiceCategoryFormPage.vue'
import CitiesPage from '@/pages/CitiesPage.vue'
import CityFormPage from '@/pages/CityFormPage.vue'
import DocumentTypesPage from '@/pages/DocumentTypesPage.vue'
import DocumentTypeFormPage from '@/pages/DocumentTypeFormPage.vue'
import DocumentsPage from '@/pages/DocumentsPage.vue'
import DocumentFormPage from '@/pages/DocumentFormPage.vue'
import { PermissionType } from '@/data/permissions'

const routes: Array<RouteRecordRaw & { meta?: { permission?: PermissionType } }> = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardPage
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersPage
  },
  {
    path: '/orders/create',
    name: 'order-create',
    component: OrderFormPage
  },
  {
    path: '/orders/:id',
    name: 'order-update',
    component: OrderFormPage
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage
  },
  {
    path: '/users/:id',
    name: 'user-update',
    component: UserFormPage
  },
  {
    path: '/shops',
    name: 'shops',
    component: ShopsPage
  },
  {
    path: '/shops/:id',
    name: 'shop-update',
    component: ShopFormPage
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesPage
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: StatisticPage
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryPage
  },
  {
    path: '/locations',
    name: 'locations',
    component: LocationsPage
  },
  {
    path: '/locations/:id',
    name: 'location-update',
    component: LocationFormPage
  },
  {
    path: '/cities',
    name: 'cities',
    component: CitiesPage
  },
  {
    path: '/cities/:id',
    name: 'city-update',
    component: CityFormPage
  },
  {
    path: '/service-categories',
    name: 'serviceCategories',
    component: ServiceCategoriesPage
  },
  {
    path: '/service-categories/:id',
    name: 'serviceCategory-update',
    component: ServiceCategoryFormPage
  },
  {
    path: '/partners',
    name: 'partners',
    component: PartnersPage
  },
  {
    path: '/partners/:id',
    name: 'partner-update',
    component: PartnerFormPage
  },
  {
    path: '/parnters-data',
    name: 'partnersData',
    component: PartnersDataPage
  },
  {
    path: '/master-planning',
    name: 'master_planning_page',
    component: MasterPlanningPage
  },
  {
    path: '/masters',
    name: 'masters',
    component: MastersPage
  },
  {
    path: '/masters/:id',
    name: 'master-update',
    component: MasterFormPage
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesPage
  },
  {
    path: '/debtors',
    name: 'debtors',
    component: DebtorsPage
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksPage
  },
  {
    path: '/templates',
    name: 'templates',
    component: TemplatesPage
  },
  {
    path: '/documents',
    name: 'document-types',
    component: DocumentTypesPage,
    meta: { permission: 'documents' }
  },
  {
    path: '/documents/:type/edit',
    name: 'document-type-update',
    component: DocumentTypeFormPage,
    meta: { permission: 'documents' }
  },
  {
    path: '/documents/:type',
    name: 'documents',
    component: DocumentsPage,
    meta: { permission: 'documents' }
  },
  {
    path: '/documents/:type/:id',
    name: 'document-update',
    component: DocumentFormPage,
    meta: { permission: 'documents' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
