import axios from 'axios'
import { iServerResponse, iValidationErrorResponse } from '@/types/api'
import store from '@/store'
import { CredentialsException, ErrorException, ValidationException } from '@/utils/exceptions'

export const domain = process.env.NODE_ENV === 'production'
  ? 'https://belkist.reg.novene.ru'
  : 'https://belkist.reg.novene.ru'

const config = {
  baseURL: domain + '/api/v1/',
  rejectUnauthorized: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

export const instanceConfig = {
  ...config,
  transformResponse: [(data: string) => {
    const response = JSON.parse(data) as iServerResponse

    if (response.status === 401) {
      store.commit('clearUserState')
      throw new CredentialsException()
    }
    if (response.status >= 200 && response.status <= 299) {
      return response.data
    }
    if (response.status === 422) {
      throw new ValidationException(<iValidationErrorResponse> response.data)
    }
    if ('message' in <{message: string}>response.data) {
      throw new ErrorException((<{message: string}>response.data).message, response.status)
    } else {
      throw new ErrorException(<string> response.data, response.status)
    }
  }]
}

export const fileInstance = axios.create({
  ...config,
  responseType: 'blob'
})

export default axios.create(instanceConfig)
