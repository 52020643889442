<template>
  <el-form
    v-model="request.state.pending"
    ref="formRef"
    :model="form"
    :rules="rules"
    label-position="top"
    @submit.prevent="handleSubmit"
  >
    <el-row :gutter="20">
      <el-col :xs="24" :span="16">
        <el-form-item prop="title" label="Название задачи">
          <el-input v-model="form.title"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item label="Автор задачи">
          <el-input disabled :model-value="form.author" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item prop="description" label="Описание задачи">
      <el-input type="textarea" v-model="form.description" />
    </el-form-item>

    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item prop="dateStart" label="Дата постановки">
          <el-date-picker
            v-model="form.dateStart"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item prop="dateStart" label="Ожидаемая дата завершения">
          <el-date-picker
            v-model="form.dateExpected"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item prop="dateStart" label="Фактическая дата завершения">
          <el-date-picker
            v-model="form.dateEnd"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
            clearable
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item prop="executor" label="Исполнитель">
          <el-select v-model="form.executorId" filterable>
            <el-option
              v-for="user in props.users"
              :key="user.id"
              :value="user.id"
              :label="user.title"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item prop="coExecutors" label="Соисполнители">
          <el-select
            v-model="form.coExecutors"
            multiple
            collapse-tags
            filterable
          >
            <el-option
              v-for="user in props.users"
              :key="user.id"
              :value="user.id"
              :label="user.title"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item prop="statusId" label="Статус">
          <el-select v-model="form.statusId">
            <el-option
              v-for="status in statuses"
              :key="status.value"
              :value="status.value"
              :label="status.label"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="primary" native-type="submit">
      Сохранить
    </el-button>
  </el-form>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { useStore } from '@/store'
import moment from 'moment'
import { iListItem, iTask } from '@/types/models'
import TaskStatusMap from '@/data/taskStatusMap'
import useDataRequest from '@/composables/useDataRequest'
import { saveTask } from '@/api/tasks'
import { FormRules } from 'element-plus'

const props = defineProps<{
  users: iListItem[]
}>()

const store = useStore()

const statuses = Object.keys(TaskStatusMap).map(key => {
  return {
    label: TaskStatusMap[parseInt(key)].text,
    value: parseInt(key)
  }
})

const request = useDataRequest()

type iForm = {
  id: number|null
  title: string
  description: string
  dateStart: string
  dateExpected: string
  dateEnd: string
  executorId?: number
  coExecutors: Array<number>
  statusId: number
  author: string
}

const form = reactive<iForm>({
  id: null,
  title: '',
  description: '',
  dateEnd: '',
  dateExpected: '',
  dateStart: moment().format('YYYY-MM-DD'),
  executorId: undefined,
  coExecutors: [],
  statusId: 1,
  author: store.state.user.title ?? ''
})

const formRef = ref()

const rules: FormRules<iForm> = {
  title: [
    { required: true, trigger: 'blur', message: 'Обязательно' }
  ],
  dateEnd: [
    { required: true, trigger: 'blur', message: 'Обязательно' }
  ],
  dateExpected: [
    { required: true, trigger: 'blur', message: 'Обязательно' }
  ],
  dateStart: [
    { required: true, trigger: 'blur', message: 'Обязательно' }
  ]
}

const emit = defineEmits(['save'])

const handleSubmit = () => {
  formRef.value.validate((valid: boolean) => {
    if (valid) {
      const data: iTask = {
        date_end: form.dateEnd,
        date_expected: form.dateExpected,
        date_start: form.dateStart,
        status_id: form.statusId,
        id: form.id,
        coExecutors: form.coExecutors.map(id => props.users.find(item => item.id === id) as iListItem),
        executor: form.executorId ? props.users.find(item => item.id === form.executorId) ?? null : null,
        title: form.title,
        description: form.description
      }

      request.tryRequest(async () => {
        await saveTask(data)
        emit('save')
      })
    }
  })
}

const resetForm = () => {
  form.id = null
  formRef.value?.resetFields()
  formRef.value?.clearValidate()
}

const setData = (data: iTask) => {
  form.title = data.title
  form.dateEnd = data.date_end
  form.dateStart = data.date_start
  form.dateExpected = data.date_expected
  form.description = data.description
  form.author = data.user?.title || ''
  form.coExecutors = data.coExecutors.map(i => i.id)
  form.executorId = data.executor?.id ?? undefined
  form.statusId = data.status_id ?? 1
  form.id = data.id
}

defineExpose({
  setData,
  resetForm
})
</script>
