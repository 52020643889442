<template>
  <div v-loading="state.loading">
    <el-table
      ref="tableRef"
      :data="tableData"
      empty-text="Ничего не нашлось"
      table-layout="auto"
    >
      <el-table-column label="Действия" header-align="center" align="center">
        <template #default="scope">
          <el-button type="success" :icon="EditPen" circle @click="$emit('editRecord', scope.row.type)"/>
          <el-button type="warning" :icon="Menu" circle @click="$emit('viewRecord', scope.row.type)"/>
        </template>
      </el-table-column>

      <el-table-column label="Тип">
        <template #default="scope">
          <span>{{ documentTypeMap[scope.row.type].text }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Название">
        <template #default="scope">
          <el-link @click="$emit('viewRecord', scope.row.id)">{{ scope.row.title }}</el-link>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script setup lang="ts">

import { computed, reactive, ref, watch } from 'vue'
import { iDocumentType } from '@/types/models'
import { useRoute } from 'vue-router'
import { EditPen, Menu } from '@element-plus/icons-vue'
import { ElMessage, TableInstance } from 'element-plus'
import restDocumentTypes from '@/api/rest/restDocumentTypes'
import documentTypeMap from '@/data/documentTypeMap'

const route = useRoute()
defineEmits(['editRecord', 'viewRecord'])

const tableRef = ref<TableInstance>()
const state = reactive<{
  items: Array<iDocumentType>
  loading: boolean
}>({
  loading: true,
  items: []
})

const tableData = computed(() => state.items)

const load = () => {
  const queryParams = {
    params: {
      fields: ['type', 'title'].join(','),
      ...route.query
    }
  }

  state.loading = true

  restDocumentTypes.get(queryParams)
    .then(response => {
      state.items = response.data
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}

defineExpose({
  load
})

watch(() => route.query, () => {
  load()
}, { immediate: true })

</script>
