/* eslint-disable camelcase */
import { iOrderService, iService } from '@/types/models'

export type iCalculatorFields = {
  payment_part_shop: number
  payment_part_shop_extra: number
  payment_part_master: number
  payment_part_master_extra: number
  cost: number
}

const recalculatePercents = (orderServices: iOrderService[], services: iService[]): iCalculatorFields => {
  const result: iCalculatorFields = {
    payment_part_shop: 0,
    payment_part_shop_extra: 0,
    payment_part_master: 0,
    payment_part_master_extra: 0,
    cost: 0
  }

  orderServices.forEach(orderService => {
    const service = services.find(value => value.id === orderService.service_id)
    const price = parseInt(orderService.price.toString())
    if (service) {
      result.cost += price * orderService.qty
      if (service.is_additional) {
        result.payment_part_master_extra += orderService.qty * (service.is_part_master_percent === '1' ? price * service.part_master / 100 : service.part_master)
        result.payment_part_shop_extra += orderService.qty * (service.is_part_shop_percent === '1' ? price * service.part_shop / 100 : service.part_shop)
      } else {
        result.payment_part_master += orderService.qty * (service.is_part_master_percent === '1' ? price * service.part_master / 100 : service.part_master)
        result.payment_part_shop += orderService.qty * (service.is_part_shop_percent === '1' ? price * service.part_shop / 100 : service.part_shop)
      }
    }
  })

  return result
}

const useOrderCalculator = () => recalculatePercents

export default useOrderCalculator
