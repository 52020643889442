<template>
  <div>
    <user-form :record-id="recordId" @close="handleClose" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import UserForm from '@/components/users/UserForm.vue'

const router = useRouter()
const route = useRoute()

const handleClose = () => {
  router.push(route.query.returnUrl !== null
    ? {
        name: 'users',
        query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
      }
    : {
        name: 'users'
      })
}

const recordId = computed(() => (route.params.id === 'new' ? null : parseInt(String(route.params.id))) || undefined)
</script>
