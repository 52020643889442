<template>
  <p>Выбрано услуг: {{ props.services.length }}</p>

  <el-form :model="form" ref="formRef" v-loading="requestWrapper.state.pending" label-position="top">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item label="Название" prop="title" :error="requestWrapper.state.errors.title">
          <el-input v-model="form.title" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="Артикул" prop="sku" :error="requestWrapper.state.errors.sku">
          <el-input v-model="form.sku" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="Ед. измерения" prop="unit" :error="requestWrapper.state.errors.unit">
          <el-input v-model="form.unit" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="Цена" prop="price" :error="requestWrapper.state.errors.price">
          <el-input v-model="form.price" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Тип заявки" prop="selectedType" :error="requestWrapper.state.errors.order_type_id">
          <el-select v-model="form.selectedType" clearable placeholder="Не изменять" :empty-values="[false]" :value-on-clear="false">
            <el-option v-for="type in orderTypes" :key="type.id" :value="type.id" :label="type.title" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Категория" prop="selectedCategory" :error="requestWrapper.state.errors.category_id">
          <el-select v-model="form.selectedCategory" clearable placeholder="Не изменять" :empty-values="[false]" :value-on-clear="false">
            <el-option v-for="category in categories" :key="category.id" :value="category.id" :label="category.title" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Доля магазина" prop="partShop" :error="requestWrapper.state.errors.part_shop">
          <el-input v-model="form.partShop" />
        </el-form-item>
        <el-form-item prop="partShopInPrc" :error="requestWrapper.state.errors.is_part_shop_percent">
          <el-checkbox v-model="form.partShopInPrc" label="В процентах" :disabled="!form.partShop" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Доля мастера" prop="partMaster" :error="requestWrapper.state.errors.part_master">
          <el-input v-model="form.partMaster" />
        </el-form-item>
        <el-form-item prop="partMasterInPrc" :error="requestWrapper.state.errors.is_part_master_percent">
          <el-checkbox v-model="form.partMasterInPrc" label="В процентах" :disabled="!form.partMaster" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="Описание" prop="description" :error="requestWrapper.state.errors.description">
      <el-input type="textarea" v-model="form.description" />
    </el-form-item>

    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item prop="assembleMin" label="Мин. дней до сборки" :error="requestWrapper.state.errors.day_assemble_min">
          <el-input v-model="form.assembleMin" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="isAdditional" label="Доп услуга?" :error="requestWrapper.state.errors.is_additional">
          <el-select v-model="form.isAdditional" placeholder="Не изменять" :value-on-clear="null" clearable>
            <el-option :value="null" label="Не изменять" />
            <el-option value="1" label="вкл" />
            <el-option value="0" label="выкл" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="isHidden" label="Скрытая?" :error="requestWrapper.state.errors.is_hidden">
          <el-select v-model="form.isHidden" placeholder="Не изменять" :value-on-clear="null" clearable>
            <el-option :value="null" label="Не изменять" />
            <el-option value="1" label="вкл" />
            <el-option value="0" label="выкл" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="&nbsp;">
      <el-button type="success" :disabled="isDisabled" @click="handleUpdate">Применить</el-button>
    </el-form-item>
  </el-form>
</template>
<script setup lang="ts">
import { iCategory, iOrderType, iService } from '@/types/models'
import { ref, computed, reactive, watch } from 'vue'
import { saveService } from '@/api/rest/restServices'
import { ElNotification } from 'element-plus'
import { iYiiBoolean } from '@/types/forms'
import useFormRequest from '@/composables/useFormRequest'

const props = defineProps<{
  services: iService[]
  types: iOrderType[]
}>()

type iForm = {
  title: string
  price: string
  description: string
  sku: string
  unit: string
  partMaster: string
  partShop: string
  partMasterInPrc: boolean
  partShopInPrc: boolean
  assembleMin: string
  selectedType: number|null|false
  selectedCategory: number|null|false
  isHidden: null|iYiiBoolean
  isAdditional: null|iYiiBoolean
}

const form = reactive<iForm>({
  title: '',
  price: '',
  description: '',
  sku: '',
  unit: '',
  partMaster: '',
  partShop: '',
  assembleMin: '',
  partMasterInPrc: true,
  partShopInPrc: true,
  selectedType: false,
  selectedCategory: false,
  isHidden: null,
  isAdditional: null
})

const formRef = ref()

const orderTypes = computed<iOrderType[]>(() => [
  { id: false, title: 'Не изменять', categories: [] } as unknown as iOrderType,
  { id: null, title: 'Все типы', categories: [] } as unknown as iOrderType,
  ...props.types
])

const orderType = computed<iOrderType|undefined>(() => form.selectedType ? orderTypes.value.find(t => t.id === form.selectedType as unknown as number) : undefined)
const categories = computed<Array<iCategory>>(() => [
  { id: false, title: 'Не изменять' } as unknown as iCategory,
  { id: null, title: 'Все категории' } as unknown as iCategory,
  ...(orderType.value?.categories ?? [])
])

const loading = ref(false)

const isDisabled = computed(() => Object.keys(form).filter(v => {
  if (['partShopInPrc', 'partMasterInPrc'].includes(v)) {
    return false
  }
  if (['selectedType', 'selectedCategory'].includes(v)) {
    return form[v as keyof typeof form] !== false
  }
  if (['isHidden', 'isAdditional'].includes(v)) {
    return form[v as keyof typeof form] !== null
  }
  return form[v as keyof typeof form]
}).length === 0)

const requestWrapper = useFormRequest<iService>()

const emit = defineEmits(['save'])

const saveItem = async (service: iService) => {
  if (form.title) {
    service.title = form.title
  }

  if (form.description) {
    service.description = form.description
  }

  if (form.price && parseFloat(form.price)) {
    service.price = parseFloat(form.price)
  }

  if (form.selectedType !== false) {
    service.order_type_id = form.selectedType
  }

  if (form.selectedCategory !== false) {
    service.category_id = form.selectedCategory
  }

  service.old_id = null

  if (form.partMaster) {
    const val = parseInt(form.partMaster)
    if (!Number.isNaN(val)) {
      service.part_master = val
      service.is_part_master_percent = form.partMasterInPrc ? '1' : '0'
    }
  }

  if (form.partShop) {
    const val = parseInt(form.partShop)
    if (!Number.isNaN(val)) {
      service.part_shop = val
      service.is_part_shop_percent = form.partShopInPrc ? '1' : '0'
    }
  }

  if (form.unit) {
    service.unit = form.unit
  }

  if (form.sku) {
    service.sku = form.sku
  }

  if (form.isHidden !== null) {
    service.is_hidden = form.isHidden
  }

  if (form.isAdditional !== null) {
    service.is_additional = form.isAdditional
  }

  if (form.assembleMin && parseInt(form.assembleMin)) {
    service.day_assemble_min = parseInt(form.assembleMin)
  }

  if (!await requestWrapper.tryRequest(async () => {
    await saveService(service)
  })) {
    throw new Error('Ошибка валидации')
  }
}

const handleUpdate = async () => {
  try {
    loading.value = true

    for (const service of props.services) {
      await saveItem(service)
    }

    formRef.value?.resetFields()
    emit('save')
  } catch (e) {
    ElNotification({
      title: 'Ошибка',
      message: 'Ошибка при изменении услуг',
      type: 'error'
    })
  } finally {
    loading.value = false
  }
}

watch(() => form.selectedType, () => {
  form.selectedCategory = null
})

</script>
