import instance from '@/api/rest/instance'
import { iPartnerData } from '@/types/models'
import { iPartnerDataDeleteRequestParams, Nullable } from '@/types/forms'

export const updatePartnerData = (params: Nullable<iPartnerData>) => instance.put<iPartnerData>('partners/data', params, {
  params: {
    shop_id: params.shop_id,
    partner_id: params.partner_id,
    order_type_id: params.order_type_id
  }
})
export const createPartnerData = (params: Nullable<iPartnerData>) => instance.post<iPartnerData>('partners/data', params)

export const findAllPartnerData = () => instance.get<iPartnerData[]>('partners/data')

export const deletePartnerData = (params: iPartnerDataDeleteRequestParams) => instance.delete('partners/data', { params })
