<template>
  <el-form
    v-loading="formRequest.state.pending"
    ref="formRef"
    :model="form"
    :rules="rules"
  >
    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item
          v-if="props.mode === 'edit'"
          prop="shop_id"
          label="Магазин"
          :error="formRequest.state.errors.shop_id"
        >
          <el-select
            v-model="form.shop_id"
            filterable
          >
            <el-option
              v-for="option in props.shops"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-else
          required
          label="Магазин"
          :error="formRequest.state.errors.shop_id"
        >
          <el-select
            v-model="shopIds"
            filterable
            multiple
            clearable
            collapse-tags
            @change="formRequest.state.errors.shop_id = ''"
          >
            <el-option
              v-for="option in props.shops"
              :key="option.id"
              :value="option.id.toString()"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="8">
        <el-form-item prop="order_type_id" label="Тип заявки" :error="formRequest.state.errors.order_type_id">
          <el-select v-model="form.order_type_id" clearable placeholder="Все типы" @change="form.category_id = null">
            <el-option v-for="type in orderTypes" :key="type.id" :value="type.id" :label="type.title" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="8">
        <el-form-item prop="category_id" label="Категория" :error="formRequest.state.errors.category_id">
          <el-select v-model="form.category_id" clearable placeholder="Все категории">
            <el-option v-for="category in categories" :key="category.id" :value="category.id" :label="category.title" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item prop="title" label="Название" :error="formRequest.state.errors.title">
          <el-input v-model="form.title" />
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="8">
        <el-form-item prop="sku" label="Артикул" :error="formRequest.state.errors.sku">
          <el-input v-model="form.sku" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="unit" label="Ед. Измерения" :error="formRequest.state.errors.unit">
          <el-input v-model="form.unit" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="price" label="Цена" :error="formRequest.state.errors.price">
          <el-input v-model="form.price" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :span="4">
        <el-form-item prop="day_assemble_min" :error="formRequest.state.errors.day_assemble_min" label="Мин дней до сборки?">
          <el-input v-model="form.day_assemble_min" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item prop="description" label="Описание" :error="formRequest.state.errors.description">
      <el-input v-model="form.description" type="textarea" rows="10" />
    </el-form-item>

    <el-row :gutter="20">
      <el-col :xs="12" :span="4">
        <el-form-item prop="is_additional" label="Доп. услуга?">
          <el-checkbox v-model="form.is_additional" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="is_archive" label="Архив?">
          <el-checkbox v-model="form.is_archive" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="is_hidden" label="Скрытая?">
          <el-checkbox v-model="form.is_hidden" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :span="4">
        <el-form-item prop="part_master" label="Доля мастера" :error="formRequest.state.errors.part_master">
          <el-input v-model="form.part_master" />
        </el-form-item>
      </el-col>
      <el-col :xs="12" :span="4">
        <el-form-item prop="is_part_master_percent" label="В процентах">
          <el-checkbox v-model="form.is_part_master_percent" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
      <el-col :xs="12" :span="4">
        <el-form-item prop="part_shop" label="Доля магазина" :error="formRequest.state.errors.part_shop">
          <el-input v-model="form.part_shop" />
        </el-form-item>
      </el-col>
      <el-col :xs="12" :span="4">
        <el-form-item prop="is_part_shop_percent" label="В процентах">
          <el-checkbox v-model="form.is_part_shop_percent" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-button type="success" @click="handleSave">Сохранить</el-button>
  </el-form>
</template>

<script lang="ts" setup>

import { reactive, computed, ref } from 'vue'
import { iCategory, iOrderType, iService, iShop } from '@/types/models'
import { Nullable } from '@/types/forms'
import useFormRequest from '@/composables/useFormRequest'
import { FormInstance, FormRules } from 'element-plus'
import { loadServiceById, saveService } from '@/api/rest/restServices'
import { iDialogVisible } from '@/components/services/types'

const emit = defineEmits(['save', 'saveBatch'])

const formRef = ref<FormInstance>()

const shopIds = ref<number[]>([])

const form = reactive<Nullable<Record<keyof iService, string|number>>>({
  is_part_master_percent: 1,
  is_part_shop_percent: 1,
  part_master: 0,
  part_shop: 0,
  id: null,
  category_id: null,
  day_assemble_min: '0',
  description: '',
  is_additional: 0,
  is_archive: 0,
  order_type_id: '',
  price: '',
  service_group_id: '',
  shop_id: '',
  sku: '',
  title: '',
  unit: '',
  weight: '',
  is_hidden: 0
})

const rules: FormRules<iService> = {
  title: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  unit: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  price: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  sku: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  shop_id: [{ required: true, trigger: 'blur', message: 'Обязательно' }]
}

const formRequest = useFormRequest<iService>()

const props = defineProps<{
  types: iOrderType[],
  shops: iShop[],
  mode: iDialogVisible
}>()

const orderTypes = computed<iOrderType[]>(() => [
  { id: null, title: 'Все типы', categories: [] } as unknown as iOrderType,
  ...props.types
])

const orderType = computed<iOrderType|undefined>(() => form.order_type_id ? orderTypes.value.find(t => t.id === form.order_type_id) : undefined)
const categories = computed<Array<iCategory>>(() => [
  { id: null, title: 'Все категории' } as unknown as iCategory,
  ...(orderType.value?.categories ?? [])
])

const loadService = (service: iService) => {
  Object.assign(form, service)
}

const prepareForCopy = (shops: number[]) => {
  shopIds.value = shops
  form.id = null
}

const resetForm = () => {
  formRef.value?.resetFields()
  form.id = null
}

const handleSave = () => {
  formRef.value?.validate((valid: boolean) => {
    if (valid) {
      formRequest.tryRequest(async () => {
        if (props.mode === 'edit') {
          const response = await saveService(form as unknown as iService)
          const itemResponse = await loadServiceById(response.data.id)
          emit('save', itemResponse.data)
        } else {
          if (shopIds.value.length) {
            for (const shopId of shopIds.value) {
              await saveService({
                ...form as unknown as iService,
                id: null,
                shop_id: shopId
              })
            }
            emit('saveBatch')
          } else {
            formRequest.state.errors.shop_id = 'Укажите хотя бы 1 магазин'
          }
        }
      })
    }
  })
}

defineExpose({
  loadService,
  prepareForCopy,
  resetForm
})

</script>
