<template>
  <div>
    <document-types-index ref="indexRef" @edit-record="handleEdit" @view-record="handleView" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import DocumentTypesIndex from '@/components/documents/DocumentTypesIndex.vue'
import { DocumentTypeEnum } from '@/types/models'

const router = useRouter()
const route = useRoute()
const indexRef = ref<InstanceType<typeof DocumentTypesIndex>>(null)

const handleEdit = (type: DocumentTypeEnum) => {
  router.push({
    name: 'document-type-update',
    params: {
      type
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}

const handleView = (type: DocumentTypeEnum) => {
  router.push({
    name: 'documents',
    params: {
      type
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}
</script>
