<template>
  <div v-loading="request.state.pending">
    <master-planning-filter v-model="state.filter" :use-url="props.useUrl" />

    <master-planning-table
      :items="state.items"
      :days-count="daysCount"
      :start-date="startDate"
      @click-cell="changeWeekend"
      @change-period="changePeriod"
      @select="handleSelect"
      show-undetermined-orders
      show-summary
      :can-select="props.canSelect"
    />
  </div>
</template>

<script setup lang="ts">

import { computed, reactive, watch } from 'vue'
import { iMaster } from '@/types/models'
import { ElMessage } from 'element-plus'
import restMasters from '@/api/rest/restMasters'
import MasterPlanningTable from '@/components/masterPlanning/MasterPlanningTable.vue'
import restMastersWeekends from '@/api/rest/restMastersWeekends'
import MasterPlanningFilter from '@/components/masterPlanning/MasterPlanningFilter.vue'
import useDataRequest from '@/composables/useDataRequest'
import moment from 'moment'
import { useRoute } from 'vue-router'

interface iFilter {
  startDate: string
  categories: string[]
  masters: string[]
  locations: string[]
  address: string
}

const props = defineProps<{
  useUrl?: boolean
  canSelect?: boolean
  categoryId?: number
  locationId?: number
  address?: string
}>()

const request = useDataRequest()

const daysCount = 14

const state = reactive<{
  items: iMaster[]
  filter: iFilter
}>({
  filter: {
    startDate: '',
    categories: props.categoryId ? [props.categoryId.toString()] : [],
    masters: [],
    locations: props.locationId ? [props.locationId.toString()] : [],
    address: props.address ?? ''
  },
  items: []
})

const route = useRoute()
const emit = defineEmits(['select'])

if (props.useUrl) {
  if (route.query.categories) {
    state.filter.categories = (Array.isArray(route.query.categories) ? route.query.categories : [route.query.categories]) as unknown as string[]
  }
  if (route.query.masters) {
    state.filter.masters = (Array.isArray(route.query.masters) ? route.query.masters : [route.query.masters]) as unknown as string[]
  }
  if (route.query.locations) {
    state.filter.locations = (Array.isArray(route.query.locations) ? route.query.locations : [route.query.locations]) as unknown as string[]
  }
  if (route.query.startDate) {
    state.filter.startDate = route.query.startDate as string
  }
  if (route.query.address) {
    state.filter.address = route.query.address as string
  }
}

const startDate = computed(() => state.filter.startDate || moment().format('YYYY-MM-DD'))

const load = () => {
  request.tryRequest(async () => {
    const response = await restMasters.weekends({
      params: {
        fields: ['id', 'title', 'weekends', 'orders', 'is_active', 'address'].join(','),
        ...state.filter,
        startDate: startDate.value
      }
    })
    state.items = response.data.items
  })
}

const changeWeekend = (masterId: number, date?: string) => {
  const item = state.items.find(item => item.id === masterId)

  if (!item || !date) {
    ElMessage.error('Непредвиденная ошибка')
    console.error(masterId, date)
    return
  }

  const weekendIndex = item.weekends.findIndex(weekend => weekend.date === date)

  if (weekendIndex !== -1) {
    item.weekends.splice(weekendIndex, 1)
    request.tryRequest(async () => {
      await restMastersWeekends.delete(masterId, date)
    }).catch(error => {
      console.error(error)
      ElMessage.error({
        message: `
            <p>Не удалось изменить запись</p>
            <p>Мастер: ${item.title}</p>
            <p>Дата: ${date}</p>
          `,
        duration: 0,
        showClose: true,
        dangerouslyUseHTMLString: true
      })
      item.weekends.push({
        master_id: masterId,
        date
      })
    })
  } else {
    item.weekends.push({
      master_id: masterId,
      date
    })

    request.tryRequest(async () => {
      await restMastersWeekends.save({ master_id: masterId, date })
    }).catch(error => {
      console.error(error)
      ElMessage.error({
        message: `
            <p>Не удалось изменить запись</p>
            <p>Мастер: ${item.title}</p>
            <p>Дата: ${date}</p>
          `,
        duration: 0,
        showClose: true,
        dangerouslyUseHTMLString: true
      })
      item.weekends = item.weekends.filter(weekend => weekend.master_id !== masterId || weekend.date !== date)
    })
  }
}

const changePeriod = (date: string) => {
  state.filter.startDate = date
}

const handleSelect = (e: unknown) => {
  emit('select', e)
}

defineExpose({
  load
})

watch(() => Object.values(state.filter), load, { immediate: true })

</script>
