<template>
  <el-form style="margin: 20px 0;" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="16" :xs="24">
        <el-form-item prop="title" label="Название">
          <el-input v-model="state.filter.title" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="status" label="Статус">
          <el-select v-model="state.filter.status" clearable>
            <el-option v-for="status in statusOptions" :key="status.value" :label="status.label" :value="status.value" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" :xs="24">
        <el-form-item prop="dateStart" label="Дата постановки">
          <el-date-picker
            v-model="state.filter.dateStart"
            type="daterange"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            :editable="false"
            :shortcuts="pickerShortcuts.pastRange"
            unlink-panels
          />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="dateExpected" label="Дата завершения">
          <el-date-picker
            v-model="state.filter.dateExpected"
            type="daterange"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            :editable="false"
            :shortcuts="pickerShortcuts.pastRange"
            unlink-panels
          />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="dateEnd" label="Фактическая дата завершения">
          <el-date-picker
            v-model="state.filter.dateEnd"
            type="daterange"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            :editable="false"
            :shortcuts="pickerShortcuts.pastRange"
            unlink-panels
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12" :xs="24">
        <el-form-item prop="executor" label="Исполнитель">
          <el-select v-model="state.filter.executor"
                     style="width: 100%"
                     clearable
                     automatic-dropdown
                     filterable
          >
            <el-option v-for="user in users" :key="user.id" :value="user.id" :label="user.title" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item prop="user" label="Автор">
          <el-select v-model="state.filter.user"
                     style="width: 100%"
                     clearable
                     automatic-dropdown
                     filterable
          >
            <el-option v-for="user in users" :key="user.id" :value="user.id" :label="user.title" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="success" native-type="submit">Применить</el-button>
    <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
  </el-form>
</template>

<script lang="ts" setup>

import { computed, onMounted, reactive, ref, watch } from 'vue'

import taskStatusMap from '@/data/taskStatusMap'
import pickerShortcuts from '@/utils/pickerShortcuts'
import { iListItem } from '@/types/models'
import { extractDateStringValue, makeDateFilterValue } from '@/utils/filter'
import { useRoute, useRouter } from 'vue-router'
import useDataRequest from '@/composables/useDataRequest'
import { loadUsers as fetchUsers } from '@/api/tasks'

type iFilter = {
  title: string
  status: number|undefined
  dateStart: Array<Date|string> | undefined
  dateExpected: Array<Date|string> | undefined
  dateEnd: Array<Date|string> | undefined
  user: number|string|undefined
  executor: number|string|undefined
}

export type iFilterValues = {
  // eslint-disable-next-line no-unused-vars
  [index in keyof iFilter]: string|undefined;
}

export type iQueryParams = iFilterValues

const emit = defineEmits(['loading'])

const router = useRouter()
const route = useRoute()
const request = useDataRequest()

const state = reactive<{ filter: iFilter }>({
  filter: {
    title: '',
    status: undefined,
    dateStart: undefined,
    dateExpected: undefined,
    dateEnd: undefined,
    user: undefined,
    executor: undefined
  }
})

const isFiltered = computed(() => route.query.title || route.query.status || route.query.dateStart || route.query.dateEnd || route.query.user || route.query.executor)

const users = ref<iListItem[]>([])

const statusOptions = computed(() => Object.keys(taskStatusMap).map(value => {
  const item = taskStatusMap[parseInt(value)]
  return {
    label: item.text,
    value: parseInt(value)
  }
}))

const getFilterQuery = (): iFilterValues => ({
  title: state.filter.title,
  status: state.filter.status?.toString() || '',
  dateStart: makeDateFilterValue(state.filter.dateStart),
  dateEnd: makeDateFilterValue(state.filter.dateEnd),
  dateExpected: makeDateFilterValue(state.filter.dateExpected),
  user: state.filter.user?.toString() || '',
  executor: state.filter.executor?.toString() || ''
})

const doFilter = () => {
  const filterQuery: iFilterValues = getFilterQuery()
  const query: iQueryParams = { ...filterQuery }

  router.push({ query })
}

const doReset = () => {
  router.push({ query: {} })
}

const loadUsers = () => {
  return request.tryRequest(async () => {
    const response = await fetchUsers()
    users.value = response.data.map(user => ({ id: user.id, title: user.title }))
  })
}

watch(() => route.query, () => {
  const filter = state.filter
  const query = route.query
  filter.title = query.title as string || ''
  filter.status = query.status ? parseInt(query.status as string) : undefined
  filter.dateStart = extractDateStringValue(query.dateStart)
  filter.dateExpected = extractDateStringValue(query.dateExpected)
  filter.dateEnd = extractDateStringValue(query.dateEnd)
  filter.user = query.user ? parseInt(query.user as string) : undefined
  filter.executor = query.executor ? parseInt(query.executor as string) : undefined
}, { immediate: true })

onMounted(async () => {
  emit('loading', true)

  await loadUsers()

  emit('loading', false)
})

defineExpose({ getFilterQuery, users })

</script>
