import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { iCity } from '@/types/models'
import { iRestCollection, iRestCollectionList } from '@/types/responses'
import { iCityForm, Nullable } from '@/types/forms'

export default {
  get: (params = {}): AxiosPromise<iRestCollection<iCity>> => instance.get('cities', params),
  list: (params = {}): AxiosPromise<iRestCollectionList<iCity>> => instance.get('cities/list', params),
  getById: (id: number, params = {}): AxiosPromise<iCity> => instance.get('cities/' + id, params),
  save: (params: Nullable<iCityForm>) => {
    if (params.id) return instance.put('cities/' + params.id, params)
    else return instance.post('cities', params)
  }
}
