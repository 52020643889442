<template>
  <el-upload
    v-model:file-list="fileList"
    :action="uploadAction"
    :headers="uploadHeaders"
    multiple
    :disabled="props.disabled"
    :before-remove="beforeFileRemove"
    :on-preview="handleFilePreview"
    :on-success="handleFileSuccess"
    :on-remove="handleFileRemove"
  >
    <el-button type="primary" :disabled="props.disabled">Добавить файл</el-button>
  </el-upload>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import instance from '@/api/instance'
import { useStore } from '@/store'
import { ElMessageBox, ElNotification, UploadUserFile } from 'element-plus'
import { AxiosResponse } from 'axios'
import { downloadFile as downloadFileRequest } from '@/api/files'
import { downloadFile } from '@/utils/downloadFile'
import { iServerResponse } from '@/types/api'
import { iListItem, iOrderFile } from '@/types/models'

interface iCustomUpload extends UploadUserFile {
  id?: number
  response?: AxiosResponse<iCustomUpload>
}

const props = defineProps<{
  modelValue: Array<iOrderFile>,
  disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const fileList = ref<iCustomUpload[]>([])

const store = useStore()

const uploadAction = instance.defaults.baseURL + 'files/upload'
const uploadHeaders = computed(() => ({ 'X-Api-Key': store.state.token }))

const beforeFileRemove = (uploadFile: iCustomUpload) => ElMessageBox.confirm(`Удалить ${uploadFile.name} ?`).then(() => true, () => false)

const handleFilePreview = (uploadFile: iCustomUpload) => {
  const id = uploadFile.id || uploadFile.response?.data.id
  if (id) {
    downloadFileRequest(id).then(response => {
      downloadFile(response, uploadFile.name)
    })
  }
}

const handleFileSuccess = (response: iServerResponse<iListItem>) => {
  emit('update:modelValue', [...props.modelValue, response.data])
}

const handleFileRemove = (file: iCustomUpload) => {
  const id = file.id || file.response?.data.id || undefined
  if (id) {
    const copy = [...props.modelValue]
    copy.splice(copy.findIndex(f => f.id === id), 1)
    emit('update:modelValue', copy)
  } else {
    ElNotification({
      title: 'Ошибка',
      type: 'error',
      message: 'Не удалось удалить файл'
    })
  }
}

watch(() => props.modelValue, () => {
  if (props.modelValue.length !== fileList.value.length) {
    fileList.value = props.modelValue.map<iCustomUpload>(value => ({
      name: value.title,
      id: value.id,
      url: value.path
    }))
  }
})

</script>
