<template>
  <div>
    <el-button @click="handleCreate">
      Новый магазин
    </el-button>
    <shop-index ref="indexRef" @edit-record="handleEdit" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ShopIndex from '@/components/shops/ShopIndex.vue'

const router = useRouter()
const route = useRoute()
const indexRef = ref<InstanceType<typeof ShopIndex>>(null)

const handleCreate = () => {
  handleEdit('new')
}
const handleEdit = (id: number|'new') => {
  router.push({
    name: 'shop-update',
    params: {
      id
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}

</script>
