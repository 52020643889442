<template>
  <el-menu
    :mode="mode"
    :default-active="route.name"
  >
    <template v-for="menuItem in filteredMenu" :key="menuItem.index">
      <template v-if="'items' in menuItem">
        <el-sub-menu v-if="menuItem.items.length > 1" :index="menuItem.index">
          <template #title>{{ menuItem.title }}</template>
          <el-menu-item v-for="menuItem in menuItem.items" :key="menuItem.index" :index="menuItem.index">
            <router-link :to="{ name: menuItem.index }">{{ menuItem.title }}</router-link>
          </el-menu-item>
        </el-sub-menu>

        <el-menu-item v-else :index="menuItem.items[0].index">
          <router-link :to="{ name: menuItem.items[0].index }">{{ menuItem.items[0].title }}</router-link>
        </el-menu-item>
      </template>

      <el-menu-item v-else :index="menuItem.index">
        <router-link :to="{ name: menuItem.index }">{{ menuItem.title }}</router-link>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { isArray } from 'lodash'
import { permissions } from '@/data/permissions'
import { useRoute } from 'vue-router'

interface iMenuItem {
  index: string
  title: string
  permission?: keyof typeof permissions
  items?: iMenuItem[]
}

const route = useRoute()
const store = useStore()

const props = defineProps<{
  isVertical?: boolean
}>()

const mode = computed(() => props.isVertical ? 'vertical' : 'horizontal')

const menu: iMenuItem[] = [
  {
    index: '1',
    title: 'Логист',
    items: [
      {
        index: 'orders',
        title: 'Заявки'
      }
    ]
  },
  {
    index: 'tasks',
    title: 'Задачи'
  },
  {
    index: '2',
    title: 'Настройки',
    items: [
      {
        index: 'users',
        title: 'Пользователи'
      },
      {
        index: 'partners',
        title: 'Партнеры'
      },
      {
        index: 'shops',
        title: 'Магазины'
      },
      {
        index: 'partnersData',
        title: 'Связь магазинов с партнерами'
      },
      {
        index: 'locations',
        title: 'Локации'
      },
      {
        index: 'cities',
        title: 'Города'
      },
      {
        index: 'statistic',
        title: 'Статистика'
      },
      {
        index: 'history',
        title: 'Журнал'
      },
      {
        index: 'services',
        title: 'Услуги'
      },
      {
        index: 'serviceCategories',
        title: 'Категории услуг'
      },
      {
        index: 'masters',
        title: 'Мастера'
      },
      {
        index: 'master_planning_page',
        title: 'Мастера - планирование'
      },
      {
        index: 'roles',
        title: 'Роли'
      },
      {
        index: 'debtors',
        title: 'Должники'
      },
      {
        index: 'templates',
        title: 'Шаблоны печати'
      },
      {
        index: 'document-types',
        title: 'Документы',
        permission: 'documents'
      }
    ]
  }
]

const filteredMenu = computed(() => store.state.user.isAdmin ? menu : menu.map(filterMenu).filter(Boolean))

const filterMenu = (menuItem: iMenuItem) => {
  if (!isArray(menuItem.items)) {
    if (!store.getters.can(menuItem.permission || menuItem.index)) {
      return undefined
    }
  } else {
    menuItem.items = menuItem.items.map(filterMenu).filter(Boolean) as iMenuItem[]

    if (!menuItem.items.length) {
      return undefined
    }
  }

  return menuItem
}

</script>

<style lang="scss">

.el-menu {
  .el-menu-item {
    padding: 0;

    a {
      padding: 0 20px;
      text-decoration: none;
    }
  }
}

.el-menu.el-menu--popup {
  li.el-menu-item {
    padding: 0;

    a {
      color: var(--el-menu-text-color);
      padding: 0 20px;
      transition: color ease .2s;
      width: 100%;
    }

    &:hover a,
    &.is-active a {
      color: var(--el-menu-hover-text-color);
    }
  }
}

</style>
