<template>
  <div class="m20">
    <el-button type="primary" :disabled="props.disabled" @click="emit('copy')">Копирование</el-button>
    <el-button type="danger" :disabled="props.disabled" @click="emit('archive')">Архивирование</el-button>
    <el-button type="success" :disabled="props.disabled" @click="emit('bulk')">Массовое изменение</el-button>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  disabled?: boolean
}>()

const emit = defineEmits(['copy', 'bulk', 'archive'])

</script>
