<template>
  <div v-loading="request.state.pending">
    <partner-data-filter />
    <el-button type="primary" @click="handleCreate">Добавить</el-button>
    <partner-data-table :partner-data="partnerData" @delete="handleDelete" />
  </div>
  <el-dialog v-model="isFormVisible">
    <partner-data-form ref="formRef" :partner-data="partnerData" @save="handleSave" />
  </el-dialog>
</template>

<script lang="ts" setup>
import PartnerDataFilter from '@/components/partnersData/PartnerDataFilter.vue'
import PartnerDataTable from '@/components/partnersData/PartnerDataTable.vue'
import { nextTick, ref, onMounted } from 'vue'
import PartnerDataForm from '@/components/partnersData/PartnerDataForm.vue'
import { iPartnerData } from '@/types/models'
import { deletePartnerData, findAllPartnerData } from '@/api/rest/restPartnerData'
import useDataRequest from '@/composables/useDataRequest'
import { ElNotification } from 'element-plus'

const isFormVisible = ref(false)
const formRef = ref()

const request = useDataRequest()
const partnerData = ref<iPartnerData[]>([])

const handleDelete = (item: iPartnerData) => {
  const index = partnerData.value.findIndex(p => p.shop_id === item.shop_id && p.order_type_id === item.order_type_id && p.partner_id === item.partner_id)
  const newValue = [...partnerData.value]
  newValue.splice(index, 1)
  partnerData.value = newValue

  request.tryRequest(async () => {
    await deletePartnerData({
      partner_id: item.partner_id,
      shop_id: item.shop_id,
      order_type_id: item.order_type_id
    })

    ElNotification({
      title: 'Успешно',
      message: 'запись удалена',
      type: 'success'
    })
  })
}

const handleCreate = () => {
  isFormVisible.value = true
  nextTick(() => {
    formRef.value.createItem()
  })
}

const handleSave = (newPartnerData: iPartnerData) => {
  const newValue = [...partnerData.value]
  const itemIndex = newValue.findIndex(p => p.shop_id === newPartnerData.shop_id && p.order_type_id === newPartnerData.order_type_id && p.partner_id === newPartnerData.partner_id)
  if (itemIndex >= 0) {
    newValue.splice(itemIndex, 1, newPartnerData)
  } else {
    newValue.push(newPartnerData)
  }
  partnerData.value = newValue

  isFormVisible.value = false
}

onMounted(() => {
  request.tryRequest(async () => {
    const response = await findAllPartnerData()
    partnerData.value = response.data
  })
})
</script>
