import instance from '@/api/rest/instance'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { iFile, iMaster } from '@/types/models'
import { iRestCollection, iRestCollectionList } from '@/types/responses'
import { iMasterForm, Nullable } from '@/types/forms'
import { domain, fileInstance } from '@/api/instance'

export const masterUrls = {
  savePassport: (masterId: number) => instance.defaults.baseURL + 'masters/' + masterId + '/passport',
  viewPassport: (masterId: number, id: number) => instance.getUri({
    url: 'masters/' + masterId + '/passport/' + id,
    params: { 'access-token': instance.defaults.headers.common['X-Api-Key'] }
  })
}

export default {
  get: (config: AxiosRequestConfig = {}): AxiosPromise<iRestCollection<iMaster>> => instance.get('masters', config),
  list: (params = {}): AxiosPromise<iRestCollectionList<iMaster>> => instance.get('masters/list', params),
  getById: (id: number, params = {}): AxiosPromise<iMaster> => instance.get('masters/' + id, params),
  save: (params: Nullable<iMasterForm>): AxiosPromise<iMaster> => {
    if (params.id) return instance.put('masters/' + params.id, params)
    else return instance.post('masters', params)
  },
  passport: {
    get: (masterId: number, params = {}): AxiosPromise<iRestCollection<iFile>> => instance.get('masters/' + masterId + '/passport', params),
    view: (masterId: number, id: number): AxiosPromise<Blob> => fileInstance.get(instance.defaults.baseURL + 'masters/' + masterId + '/passport/' + id, { baseURL: domain }),
    delete: (masterId: number, id: number, params = {}) => instance.delete('masters/' + masterId + '/passport/' + id, params)
  },
  weekends: (config: AxiosRequestConfig = {}): AxiosPromise<iRestCollection<iMaster>> => instance.get('masters/weekends', config)
}
