<template>
  <div v-if="!props.shopId">Выберите магазин</div>
  <template v-else>
    <el-form>
      <el-form-item>
        <el-input placeholder="Поиск" v-model="state.filter.title" clearable />
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      :show-header="false"
      :row-class-name="tableRowClassName"
      empty-text="Выберите магазин"
    >
      <el-table-column prop="title" />
      <el-table-column prop="unit" width="100" />
      <el-table-column prop="price" width="100" />
      <el-table-column width="180">
        <template #default="scope">
          <el-input-number
            v-if="scope.row.id !== undefined"
            :model-value="props.modelValue[props.modelValue.findIndex(v => v.service_id === scope.row.id)]?.qty || 0"
            @update:modelValue="v => updateValue(scope.row.id, v, scope.row.price)"
            :min="0"
          />
          <template v-else>
            {{ scope.row.qty }}
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="total" width="100" />
    </el-table>
  </template>
</template>

<script setup lang="ts">

import { computed, reactive, watch } from 'vue'
import { iService, iOrderService } from '@/types/models'
import { tableRowClassName, makeServiceTableData } from '@/composables/useServicesTable'

const props = defineProps<{
  shopId: number | undefined,
  orderTypeId?: number
  categoryId?: number
  services: Array<iService>
  modelValue: Array<iOrderService>
  showHiddenServices?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

interface StateType {
  filter: {
    title: string
  }
}

const state = reactive<StateType>({
  filter: {
    title: ''
  }
})

const tableData = computed(() => {
  const services = props.services
    .filter(service =>
      (props.showHiddenServices || !service.is_hidden) &&
      service.title.toLowerCase().includes(state.filter.title.toLowerCase()) &&
      service.order_type_id === props.orderTypeId &&
      service.category_id === props.categoryId
    )
    .map(service => ({
      ...service,
      qty: props.modelValue.find(i => i.service_id === service.id)?.qty || 0
    }))

  return makeServiceTableData(services)
})

const updateValue = (id: number, qty: number, price: number) => {
  const model = [...props.modelValue]
  const index = model.findIndex(v => v.service_id === id)
  if (index < 0) {
    model.push({
      service_id: id,
      price,
      qty
    })
  } else {
    if (qty) {
      model[index].qty = qty
    } else {
      model.splice(index, 1)
    }
  }
  emit('update:modelValue', model)
}

watch(() => props.services, async (newServices, oldServices) => {
  const result: iOrderService[] = []

  if (!oldServices.length) {
    props.modelValue.forEach(item => {
      if (newServices.find(newService => newService.id === item.service_id)) {
        result.push(item)
      }
    })
  }

  emit('update:modelValue', result)
})

watch(() => props.shopId, () => {
  state.filter.title = ''
})

</script>
