<template>
  <div class="field-set-select-block">
    <el-select v-model="value" style="width: 250px; margin-right: 20px;">
      <el-option :value="0" label="Шаблон по умолчанию" />
      <el-option v-for="item in items" :key="item.id" :label="item.title" :value="item.id" />
    </el-select>
    <el-button :icon="Setting" @click="showForm = true" />

    <el-dialog v-model="showForm">
      <field-set-form ref="setFormRef" :selected="value" @save="handleSave" />
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from '@/store'
import { computed, ref } from 'vue'
import { Setting } from '@element-plus/icons-vue'
import FieldSetForm from '@/components/orders/FieldSetForm.vue'
import { ElNotification } from 'element-plus'

const store = useStore()
const showForm = ref<boolean>(false)

const items = computed(() => store.state.orderFieldSets)

const handleSave = () => {
  ElNotification({
    title: 'Успешно',
    message: 'Шаблон сохранен',
    type: 'success'
  })
  showForm.value = false
}

const value = computed({
  get () {
    return store.state.orderFieldSets.find(set => set.active)?.id || 0
  },
  set (value) {
    store.dispatch('setActiveFieldSet', value || null)
  }
})

defineExpose({
  value
})
</script>

<style lang="scss">
.field-set-select-block{
  display: flex;
  justify-content: space-between;
}
</style>
