import moment from 'moment'
import { phoneMask } from '@/utils/maska'

export const dateDecode = (date: string|null) => date ? (date === '1970-01-01' ? '' : moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')) : ''
export const dateTimeDecode = (date: string|null) => date ? moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss') : ''

export const formatCurrency = (value: number|string) => parseFloat(value as string).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })
export const formatTime = (datetime: string) => moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')

export const formatPhone = (phone: string|null) => {
  if (!phone) {
    return phone
  }

  let formattedPhone = phone.replace(/\D/, '')

  if (formattedPhone.length >= 11) {
    formattedPhone = '7' + formattedPhone.slice(1)
  }

  return phoneMask.masked(formattedPhone)
}

export const formatBool = (value: boolean|number) => value ? 'Да' : 'Нет'

export const pluralize = (value: number, words: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2]
  return words[(value % 100 > 4 && value % 100 < 20) ? 2 : cases[Math.min(value % 10, 5)]]
}
