<template>
  <shop-form :record-id="recordId" @close="handleClose" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ShopForm from '@/components/shops/ShopForm.vue'

const router = useRouter()
const route = useRoute()

const handleClose = () => {
  router.push(route.query.returnUrl !== null
    ? {
        name: 'shops',
        query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
      }
    : {
        name: 'shops'
      })
}

const recordId = computed(() => (route.params.id === 'new' ? null : parseInt(String(route.params.id))) || undefined)
</script>
