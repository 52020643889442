<template>
  <div>
    <el-button @click="handleCreate">
      Новая категория услуг
    </el-button>
    <service-category-index ref="indexRef" @edit-record="handleEdit" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ServiceCategoryIndex from '@/components/serviceCategories/ServiceCategoryIndex.vue'

const router = useRouter()
const route = useRoute()
const indexRef = ref<InstanceType<typeof ServiceCategoryIndex>>(null)

const handleCreate = () => {
  handleEdit('new')
}
const handleEdit = (id: number|'new') => {
  router.push({
    name: 'serviceCategory-update',
    params: {
      id
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}

</script>
