<template>
  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
    @submit.prevent="submitForm"
  >
    <div class="form-title">
      <h2 v-if="!isUpdateForm">Новый город</h2>
      <h2 v-else>Редактирование города {{ recordTitle }}</h2>

      <el-button @click="$emit('close')">Назад</el-button>
    </div>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="location_id" label="Локация" :error="state.errors.location_id">
          <el-select
            v-model="state.form.location_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in locations"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="6">
        <el-form-item prop="title" label="Наименование" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { iBaseFormState, iCityForm } from '@/types/forms'
import { iCity, iLocation } from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restCities from '@/api/rest/restCities'
import restLocations from '@/api/rest/restLocations'

type StateInterface = iBaseFormState<iCityForm>

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const formRef = ref<FormInstance>()
const isUpdateForm = !!props.recordId
const recordTitle = ref('')
const locations = ref<iLocation[]>([])

const state = reactive<StateInterface>({
  loading: false,
  form: {
    id: null,
    location_id: null,
    title: ''
  },
  errors: {}
})

const rules = computed<FormRules<iCity>>(() => {
  const rules = {} as FormRules<iCity>

  const fields: Array<keyof iCity> = ['title']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  return rules
})

const loadForm = (recordId: number) => {
  state.loading = true

  restCities.getById(recordId)
    .then(response => {
      const responseModel = response.data

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iCityForm

        if (!(key in responseModel)) continue

        state.form[key] = responseModel[key]
      }

      recordTitle.value = responseModel.title
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const form = { ...state.form }

  try {
    const response = await restCities.save(form)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}

const loadLocations = () => restLocations.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    locations.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })

onMounted(async () => {
  state.loading = true
  await loadLocations()

  state.loading = false

  if (!props.recordId) return

  loadForm(props.recordId)
})

</script>
