<template>
  <form-manager v-if="isExtended" :id="orderId" :show-close="hasReturnUrl" @close="handleClose" @save="handleSave" v-loading="request.state.pending" />
  <form-seller v-else :show-close="hasReturnUrl" @close="handleClose" @save="handleSave" v-loading="request.state.pending" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'

import FormManager from '@/components/orders/form/FormManager.vue'
import FormSeller from '@/components/orders/form/FormSeller.vue'
import { loadPrint } from '@/api/orders'
import printText from '@/utils/print'
import { ErrorException } from '@/utils/exceptions'
import { ElNotification } from 'element-plus'
import useDataRequest from '@/composables/useDataRequest'

const store = useStore()

const route = useRoute()
const router = useRouter()

const request = useDataRequest()

const orderId = computed(() => (route.params.id as unknown as number|null) || null)
const isExtended = computed(() => store.getters.can('order_form_extended'))
const hasReturnUrl = computed(() => route.query.returnUrl !== null)

const handleSave = async (id: string, printImmediately: boolean) => {
  if (printImmediately) {
    await print(+id)
  }

  handleClose()
}

const print = async (id: number) => {
  try {
    await request.tryRequest(async () => {
      const printResponse = await loadPrint(id)
      if (printResponse.data) {
        await printText(printResponse.data)
      }
    })
  } catch (error: unknown) {
    if (error instanceof ErrorException) {
      ElNotification({
        title: error.message,
        type: 'error'
      })
    } else {
      throw error
    }
  }
}

const handleClose = () => {
  router.push(route.query.returnUrl !== null
    ? {
        name: 'orders',
        query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
      }
    : {
        name: 'orders'
      })
}
</script>
