<template>
  <el-form
    ref="formRef"
    label-position="top"
    :model="form"
    :rules="rules"
    v-loading="request.state.pending"
  >
    <div class="form-title">
      <h2 v-if="!isUpdateForm">Новый партнер</h2>
      <h2 v-else>Редактирование партнера {{ recordTitle }}</h2>

      <el-button @click="$emit('close')">Назад</el-button>
    </div>

    <el-form-item prop="title" label="Наименование" :error="request.state.errors.title">
      <el-input v-model="form.title"/>
    </el-form-item>
    <el-form-item prop="phone" label="Телефон" :error="request.state.errors.phone">
      <el-input v-mask data-maska="+7 (###) ###-##-##" v-model="form.phone"/>
    </el-form-item>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { FormInstance, FormRules } from 'element-plus'
import { Nullable } from '@/types/forms'
import {
  iPartner
} from '@/types/models'
import restPartners from '@/api/rest/restPartners'

import { vMaska as vMask } from 'maska'

import useFormRequest from '@/composables/useFormRequest'
import { useStore } from '@/store'

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const store = useStore()

const formRef = ref<FormInstance>()
const recordTitle = ref('')
const partners = computed(() => store.state.partners)

const request = useFormRequest()

const isUpdateForm = !!props.recordId

const form = reactive<Nullable<Partial<iPartner>>>({
  id: null,
  title: '',
  phone: ''
})

const rules = computed<FormRules<iPartner>>(() => {
  const rules = {} as FormRules<iPartner>

  const fields: Array<keyof iPartner> = ['title']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  rules.phone = [
    { required: true, trigger: 'blur', message: 'Обязательно' },
    { pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, trigger: 'blur', message: 'Укажите телефон полностью' }
  ]

  return rules
})

const loadForm = (id: number) => {
  Object.assign(form, partners.value.find(item => item.id === id))
}

const submitForm = () => {
  request.tryRequest(async () => {
    const response = await restPartners.save({ ...form })
    if (form.id) {
      const partner = partners.value.find(item => item.id === form.id)
      if (partner) {
        partner.title = form.title
        partner.phone = form.phone
      }
    } else {
      partners.value.push(response.data)
    }
    emit('save', response.data)
    emit('close')
  })
}

onMounted(async () => {
  if (!props.recordId) return

  loadForm(props.recordId)
})

</script>
