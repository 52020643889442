<template>
  <h2>Статистика заказов</h2>

  <el-form label-position="left">
    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item label="Магазин">
          <multiple-select :options="shops" v-model="state.shops" placeholder="Все" />
        </el-form-item>
      </el-col>
    </el-row>

    <dashboard-counters :shops="state.shops" />

    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item label="Статус">
          <multiple-select :options="statuses" v-model="state.statuses" placeholder="Все" />
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="8">
        <el-form-item label="за период">
          <el-date-picker
            v-model="state.date"
            type="daterange"
            start-placeholder="Дата заказа С"
            end-placeholder="Дата заказа По"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts.pastRange"
            unlink-panels
          />
        </el-form-item>
      </el-col>
    </el-row>

    <dashboard-chart-wrapper :period="state.date" :shops="state.shops" :statuses="state.statuses" />
  </el-form>

  <el-form inline @submit.prevent="state.number = state.rawNumber">
    <h2>История заказов</h2>

    <el-form-item label="Номер заказа" inline>
      <el-input v-model="state.rawNumber" clearable style="width: 180px" />
    </el-form-item>

    <el-form-item>
      <el-button :disabled="state.rawNumber === state.number" type="success" native-type="submit">Применить</el-button>
    </el-form-item>
  </el-form>

  <dashboard-history :number="state.number" />
</template>

<script lang="ts" setup>
import { computed, reactive } from 'vue'
import store from '@/store'
import MultipleSelect from '@/components/MultipleSelect.vue'
import { iMultipleSelectOption } from '@/types/forms'
import statusMap from '@/data/statusMap'
import pickerShortcuts from '@/utils/pickerShortcuts'
import moment from 'moment'
import DashboardCounters from '@/components/dashboard/DashboardCounters.vue'
import DashboardHistory from '@/components/dashboard/DashboardHistory.vue'
import DashboardChartWrapper from '@/components/dashboard/DashboardChartWrapper.vue'

const statuses: iMultipleSelectOption[] = statusMap

const state = reactive({
  shops: [],
  rawNumber: '',
  number: '',
  statuses: Object.keys(statuses).map(i => parseInt(i)).filter(status => status !== 2),
  date: [
    moment().startOf('month').format('YYYY-MM-DD'),
    moment().endOf('month').format('YYYY-MM-DD')
  ]
})

const shops = computed(() => store.state.shops)

</script>
