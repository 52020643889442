<template>
  <el-row :gutter="10">
    <el-col :span="12" :xs="24">
      <el-time-select
        v-model="timeExpectedFrom"
        start="06:00"
        :end="timeExpectedTo || '22:00'"
        step="00:30"
        value-format="HH:mm"
        format="HH:mm"
        :disabled="props.disabled"
      />
    </el-col>
    <el-col :span="12" :xs="24">
      <el-time-select
        v-model="timeExpectedTo"
        :start="timeExpectedFrom || '06:00'"
        end="22:00"
        step="00:30"
        value-format="HH:mm"
        format="HH:mm"
        :disabled="props.disabled"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

const props = defineProps<{
  modelValue?: string
  disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const timeExpectedFrom = ref()
const timeExpectedTo = ref()

watch(() => props.modelValue, () => {
  [timeExpectedFrom.value, timeExpectedTo.value] = props.modelValue ? props.modelValue.split('-') : ['', '']
})

watch(() => [timeExpectedFrom.value, timeExpectedTo.value], () => {
  if (timeExpectedFrom.value && timeExpectedTo.value) {
    emit('update:modelValue', `${timeExpectedFrom.value}-${timeExpectedTo.value}`)
  } else if (!timeExpectedFrom.value && !timeExpectedTo.value) {
    emit('update:modelValue', null)
  }
})
</script>
